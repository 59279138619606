import BaseController from 'src/lib/controller/base_controller';

import TableRowController from 'src/controllers/base/table/row_controller';

export default class BreakdownFormController extends BaseController {
  public toggleDisableRow(evt: CustomEvent<{ rowId: string }>) {
    const { rowId } = evt.detail;
    const rowControllers = this.getSpecificChildControllers<TableRowController>(TableRowController);
    const rowToToggle = rowControllers.find(rc => rc.id === rowId);
    if (rowToToggle) {
      rowToToggle.disabled = !rowToToggle.disabled;
    }
  }
}
