import { createCustomEvent } from 'src/lib/util/create_custom_event';
import { IToastDismissOptions, IToastShowOptions } from 'src/types';

export function showToast(opts: IToastShowOptions) {
  const evt = createCustomEvent('cnf-toast:show', opts);
  window.dispatchEvent(evt);
}

export function dismissAllToasts() {
  const evt = createCustomEvent('cnf-toast:dismissAll');
  window.dispatchEvent(evt);
}

export function dismissSingleToast(opts: IToastDismissOptions) {
  const evt = createCustomEvent('cnf-toast:dismiss', opts);
  window.dispatchEvent(evt);
}
