import * as ActiveStorage from 'activestorage';
import Rails from './lib/rails';

import prepareControllers from './controllers';

import ahoy from 'ahoy.js';
import flatpickr from 'flatpickr';

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

tippy('[data-tippy-content]');

import Choices from 'choices.js';
document
  .querySelectorAll('[data-select-searchable]')
  .forEach((el) => new Choices(el as HTMLSelectElement, { searchResultLimit: 8 }));

document
  .querySelectorAll('[data-select-multiple-tags]')
  .forEach(
    (el) => new Choices(el as HTMLSelectElement, { removeItemButton: true, searchResultLimit: 8 })
  );

document.querySelectorAll('[data-input-multiple-tags]').forEach(
  (el) =>
    new Choices(el as HTMLInputElement, {
      delimiter: ',',
      editItems: true,
      removeItemButton: true,
    })
);

import Chartjs from 'chart.js';
import Chartkick from 'chartkick';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ChartRegressions } from 'chartjs-plugin-regression';
Chartjs.plugins.unregister(ChartDataLabels);

(window as any).Chart = Chartjs;
(window as any).Chartkick = Chartkick;

// Init charts
const urlParams = new URLSearchParams(window.location.search);
if (urlParams.get('trendline')) {
  // (Chartjs.defaults.global.plugins as any).regressions = {
  //   type: ['linear', 'polynomial'],
  //   line: { color: 'blue', width: 3 },
  //   onCompleteCalculation: function callback(chart){ console.log("complete", chart)}
  // };
  Chartjs.plugins.register(ChartRegressions);
  // (Chartjs.defaults.global.plugins as any).trendline = {};
  // Chartjs.plugins.register({
  //   id: 'trendline',
  //   beforeInit: function (chart: any) {
  //     chart.trendline = (Chartjs.defaults.global.plugins as any).trendline;
  //   },
  //   afterRender: function (chart: Chart) {
  //     if (chart.config.type !== 'line') {
  //       return;
  //     }
  //     chart.data.datasets.forEach(function (dataset, index) {
  //       const datasetMeta = chart.getDatasetMeta(index);
  //
  //       var lastIndex = dataset.data.length - 1;
  //       var startPos = datasetMeta.data[0]._model.x;
  //       var endPos = datasetMeta.data[lastIndex]._model.x;
  //
  //       let trendStart = 0;
  //       let trendEnd = 0;
  //       dataset.data.forEach(function(data, index) {
  //         if (data == null) return;
  //         console.log(data, index);
  //       });
  //
  //
  //       var yScale;
  //       var xScale;
  //       for (var axis in chart.scales) {
  //         if ( axis[0] == 'x')
  //           xScale = chart.scales[axis];
  //         else
  //           yScale = chart.scales[axis];
  //         if ( xScale && yScale ) break;
  //       }
  //       var x1 = startPos;
  //       var x2 = endPos;
  //       var y1 = yScale.getPixelForValue(trendStart);
  //       var y2 = yScale.getPixelForValue(trendEnd);
  //
  //
  //       var drawBottom = datasetMeta.controller.chart.chartArea.bottom;
  //       var chartWidth = datasetMeta.controller.chart.width;
  //
  //       if(y1 > drawBottom) { // Left side is below zero
  //         var diff = y1 - drawBottom;
  //         var lineHeight = y1 - y2;
  //         var overlapPercentage = diff / lineHeight;
  //         var addition = chartWidth * overlapPercentage;
  //
  //         y1 = drawBottom;
  //         x1 = (x1 + addition);
  //       } else if(y2 > drawBottom) { // right side is below zero
  //         var diff = y2 - drawBottom;
  //         var lineHeight = y2 - y1;
  //         var overlapPercentage = diff / lineHeight;
  //         var subtraction = chartWidth - (chartWidth * overlapPercentage);
  //
  //         y2 = drawBottom;
  //         x2 = chartWidth - (x2 - subtraction);
  //       }
  //
  //       const ctx = chart.ctx;
  //       ctx.lineWidth = 3;
  //       ctx.setLineDash([2, 3]);
  //       ctx.beginPath();
  //       ctx.moveTo(x1, y1);
  //       ctx.lineTo(x2, y2);
  //       ctx.strokeStyle = 'rgba(169,169,169, .6)';
  //       ctx.stroke();
  //     });
  //   },
  // });
}

if (urlParams.get('datalabels')) {
  Chartjs.plugins.register(ChartDataLabels);

  Chartjs.helpers.merge((Chartjs.defaults.global.plugins as any).datalabels, {
    backgroundColor: function (context: any) {
      return context.dataset.backgroundColor;
    },
    borderColor: 'white',
    borderWidth: 2,
    borderRadius: 4,
    color: 'white',
    font: { weight: 'bold' },
    // formatter: Math.round
  });
}

// Initialise the front end framework
console.debug('[Boot] Prepare Confinus client side');

console.debug('[Boot] Prepare Rails client side libraries');
// Start Rails client side components
Rails.start();
ActiveStorage.start();

console.debug('[Boot] Register Confinus JS Components');
// Prepare client side controllers
prepareControllers();

// Set up action text
import Trix from 'trix';
import actiontext from '@rails/actiontext';
import Chart from 'chart.js';
(window as any).Trix = Trix;
