import { MDCCheckbox } from '@material/checkbox/index';
import { MDCFormField } from '@material/form-field/index';

import FormFieldController from 'src/lib/controller/form_field_controller';

export default class CheckboxController extends FormFieldController {
  public static targets = FormFieldController.targets;

  private checkbox!: MDCCheckbox | null;

  public onInitialize() {
    this.onConnect(() => {
      const checkbox = new MDCCheckbox(this.containerTarget);
      this.checkbox = checkbox;
      const formField = new MDCFormField(this.element);

      if (this.data.has('ripple')) {
        formField.input = checkbox;
      }

      if (this.data.has('indeterminate')) {
        checkbox.indeterminate = true;
      }

      return () => {
        formField.destroy();
        checkbox.destroy();
        this.checkbox = null;
      };
    });

    return super.onInitialize();
  }

  public get checked() {
    return this.checkbox!.checked;
  }

  protected get invalidClassName() {
    return 'mdc-checkbox--invalid';
  }
}
