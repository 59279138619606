import SelectController from 'src/controllers/forms/select_controller';
import BaseController from 'src/lib/controller/base_controller';
import { markAsSafeHTML } from 'src/lib/util/safe_html';
import { ICategory } from 'src/types';

export default class ProductCategoryFilterController extends BaseController {
  public items!: ICategory[];

  public onInitialize() {
    this.items = this.parseItems();
    return super.onInitialize();
  }

  public set disabled(disabled: boolean) {
    if (disabled) {
      this.selectController.empty();
    }

    this.selectController.disabled = disabled;
  }

  public set options(newOptions: ICategory[]) {
    this.data.set('categories', JSON.stringify(newOptions));
    this.items = newOptions;

    if (newOptions.length === 0) {
      this.disabled = true;
      return;
    }

    const newHtml =
      this.selectController.emptyOption +
      newOptions
        .map(category => `<option value='${category.i}'>${category.n}</option>`)
        .reduce((previous, current) => (previous += current));

    this.selectController.options = markAsSafeHTML(newHtml);
    this.disabled = false;
  }

  public get value(): string {
    return this.selectController.value;
  }

  private get selectController(): SelectController {
    return this.getSpecificChildController(SelectController) as SelectController;
  }

  private parseItems(): ICategory[] {
    const itemsStr = this.data.get('categories');

    if (itemsStr !== null) {
      return JSON.parse(itemsStr) as ICategory[];
    }

    return [] as ICategory[];
  }
}
