import BaseController from 'src/lib/controller/base_controller';
import { IFormFieldValidationEvent } from 'src/types';

export default class ErrorLocationsController extends BaseController {
  public static targets = [
    'firstName',
    'firstNameErrorMessage',
    'lastName',
    'lastNameErrorMessage',
    'email',
    'emailErrorMessage',
    'agree',
    'agreeErrorMessage',
  ];

  private declare readonly firstNameTarget: HTMLInputElement;
  private declare readonly firstNameErrorMessageTarget: HTMLParagraphElement;
  private declare readonly lastNameTarget: HTMLInputElement;
  private declare readonly lastNameErrorMessageTarget: HTMLParagraphElement;
  private declare readonly emailTarget: HTMLInputElement;
  private declare readonly emailErrorMessageTarget: HTMLParagraphElement;
  private declare readonly agreeTarget: HTMLInputElement;
  private declare readonly agreeErrorMessageTarget: HTMLParagraphElement;

  public handleFieldValidatedEvent(evt: IFormFieldValidationEvent) {
    const { detail, target } = evt;
    const errorMessageTarget = this.errorMessageTargetForFormField(target);
    if (errorMessageTarget) {
      const message = !detail.valid ? detail.errors.map(e => e.message).join(', ') : '';
      errorMessageTarget.textContent = message;
    }
  }

  private errorMessageTargetForFormField(target: HTMLElement): HTMLParagraphElement | null {
    switch (target) {
      case this.firstNameTarget:
        return this.firstNameErrorMessageTarget;
      case this.lastNameTarget:
        return this.lastNameErrorMessageTarget;
      case this.emailTarget:
        return this.emailErrorMessageTarget;
      case this.agreeTarget:
        return this.agreeErrorMessageTarget;
      default:
        return null;
    }
  }
}
