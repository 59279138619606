import FlashController from 'src/controllers/base/flash_controller';
import BaseController from 'src/lib/controller/base_controller';

export default class FlashExamplesController extends BaseController {
  public toggleFlash() {
    const fc = this.flashController;
    if (fc) {
      fc.text = 'Hello from a toggled flash box';
      fc.toggle();
    }
  }

  public revealBriefly() {
    const fc = this.flashController;
    if (fc) {
      fc.text = 'Hello from a revealed flash box';
      fc.reveal(1000);
    }
  }

  private get flashController() {
    return this.getSpecificChildController<FlashController>(FlashController);
  }
}
