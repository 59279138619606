import { AxiosResponse } from 'axios';
import MessageItemController from 'src/controllers/layouts/message_threads/message_item_controller';
import axios from 'src/lib/axios';
import BaseController from 'src/lib/controller/base_controller';
import parseDateTime from 'src/lib/util/parse_datetime_attr';
import { NotificationEvent } from 'src/types';

export default class DynamicMessagesController extends BaseController {
  public static targets = ['messages'];

  private declare readonly messagesTarget: HTMLElement;

  private messageControllers: MessageItemController[] = [];

  public onInitialize() {
    this.onConnect(() => {
      // Fix missing scroll in Mozilla
      if ('InstallTrigger' in window) {
        this.messagesTarget.style.minHeight = '0';
      }

      this.scrollToBottom();
    });

    this.onDisconnect(() => {
      this.messageControllers = [];
    });

    this.onChildControllerConnect((controller: BaseController) => {
      if (controller instanceof MessageItemController) {
        this.messageControllers.push(controller);
        this.removeAuthorsSeen();
      }
    });

    return super.onInitialize();
  }

  public load(e: CustomEvent): void {
    const {
      detail: { message },
    } = e as NotificationEvent;
    if (message.message_thread_uid === this.data.get('thread-uid')) {
      this.fetchMessage(message.uid, message.type, message.event_type);
    }
  }

  private fetchMessage(uid: string, type: string, eventType: string): void {
    axios
      .get(`/account/messages/messages/${uid}`)
      .then((response: AxiosResponse) => response.data)
      .then((html: string) => this.addMessage(html, type, eventType, uid))
      .catch((e: Error) => {
        alert(e.message);
      });
  }

  private addMessage = (html: string, type: string, eventType: string, uid: string) => {
    if (type === 'EventMessage' && eventType === 'messages_seen') {
      const previousSeen = this.messageControllers.find((controller: MessageItemController) => {
        return uid === controller.uid;
      });

      if (previousSeen) {
        this.removeItem(previousSeen);
      }
    }

    // Action Cable may not preserve order of new messages, restoring order
    const sentAt = parseDateTime(html);
    const beforeNode = this.firstItemSentAfter(sentAt);

    beforeNode
      ? beforeNode.scope.element.insertAdjacentHTML('beforebegin', html)
      : this.messagesTarget.insertAdjacentHTML('beforeend', html);

    this.scrollToBottom();
  };

  private scrollToBottom(): void {
    const listNode = this.element.children[0];
    listNode.scrollTop = listNode.scrollHeight;
  }

  // Time ago time entries:

  private firstItemSentAfter(sentAt: number): MessageItemController | undefined {
    return this.messageControllers.find(
      (controller: MessageItemController) => controller.sentAt > sentAt
    );
  }

  // Removes notification that last message was seen about it's author
  private removeAuthorsSeen(): void {
    const messageNodes = this.messageControllers.filter(
      (controller: MessageItemController) => controller.messageType === 'Message'
    );

    const lastMessageNode = messageNodes[messageNodes.length - 1];
    const technicalNodes = this.messageControllers.filter(
      (controller: MessageItemController) => controller.messageType === 'EventMessage'
    );

    if (!(lastMessageNode && technicalNodes)) {
      return;
    }

    const senderId = lastMessageNode.senderUid;

    for (const node of technicalNodes) {
      if (node.senderUid === senderId) {
        this.removeItem(node);
      }
    }
  }

  private removeItem(controller: MessageItemController) {
    const index = this.messageControllers.indexOf(controller);
    controller.scope.element.remove();
    this.messageControllers.splice(index, 1);
  }
}
