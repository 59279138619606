import {
  FormControlElement,
  IFormControlErrors,
  IFormControlValidationEvent,
  IFormFieldError,
} from 'src/types';

import FormControlController from 'src/controllers/form_control_controller';
import i18n from 'src/lib/i18n';
import { createCustomEvent } from 'src/lib/util/create_custom_event';
import { generateFormValidationMessage } from 'src/lib/util/form_validation_messages';

import BaseController from './base_controller';

export default abstract class FormFieldController extends BaseController {
  // Since this is an abstract class, and our classes are transpiled down to ES5,
  // we need to make sure these are explicitly hoisted by calling the following in the subclass:
  // public static targets = FormFieldController.targets;
  public static targets = ['container', 'helperText'];

  protected declare readonly containerTarget: HTMLDivElement;
  protected declare readonly hasContainerTarget: HTMLDivElement;
  protected declare readonly helperTextTarget: HTMLParagraphElement;
  protected declare readonly hasHelperTextTarget: boolean;

  // Override with the 'invalid' class name modifier for this form field.
  protected abstract get invalidClassName(): string;

  public handleControlValidatedEvent(evt: IFormControlValidationEvent) {
    const {
      target,
      detail: { errors, valid },
    } = evt;
    this.handleValidationResponse(target, valid, errors);
  }

  public validate() {
    // Ask the form control to carry out the validation.
    const fc = this.fieldControl;
    const { valid, errors: controlErrors } = fc.validate();

    // Convert the errors into human-readable and return.
    const errors = this.handleValidationResponse(fc.formControlElement, valid, controlErrors);
    return {
      errors,
      valid,
    };
  }

  public focusControl() {
    // Proxy through to field_control.
    this.fieldControl.focusControl();
  }

  public get disabled() {
    // Proxy through to field_control.
    // May need to override/augment on a case-by-case basis.
    return this.fieldControl.disabled;
  }

  protected set valid(isValid: boolean) {
    this.element.classList.toggle('cnf-form-field--invalid', !isValid);
    if (this.hasContainerTarget) {
      this.containerTarget.classList.toggle(this.invalidClassName, !isValid);
    }
  }

  protected generateErrorMessage(customMessageKey: string, defaultMessageKey: string, values = {}) {
    const rawMessage =
      this.data.get(customMessageKey) || this.generateDefaultMessage(defaultMessageKey);
    return generateFormValidationMessage(rawMessage, values);
  }

  private get fieldControl(): FormControlController {
    const fieldControl = this.getSpecificChildController<FormControlController>(
      FormControlController
    );
    if (!fieldControl) {
      throw new Error(
        'Could not find a form control child inside this form field. Please check the DOM structure.'
      );
    }
    return fieldControl;
  }

  private handleValidationResponse(
    fieldControlTarget: FormControlElement,
    isValid: boolean,
    errors: IFormControlErrors
  ) {
    this.valid = isValid;
    const parsedErrors = this.parseErrorMessages(errors);
    this.helperTextContent = parsedErrors.length > 0 ? parsedErrors[0].message : '';

    // Push out an event that a higher controller can respond to
    this.emitFormFieldValidatedEvent(fieldControlTarget, isValid, parsedErrors);

    return parsedErrors;
  }

  private set helperTextContent(content: string) {
    if (this.hasHelperTextTarget) {
      this.helperTextTarget.textContent = content;
    }
  }

  private parseErrorMessages(errors: IFormControlErrors): IFormFieldError[] {
    const errorResponse = [];
    if (errors.missingValue) {
      errorResponse.push({
        code: 'missingValue',
        message: this.generateErrorMessage('validationMessageRequired', 'blank'),
      });
    }
    if (errors.wrongLength === 'over') {
      errorResponse.push({
        code: 'lengthOver',
        message: this.generateErrorMessage('validationMessageLengthOver', 'invalid'),
      });
    }
    if (errors.wrongLength === 'under') {
      errorResponse.push({
        code: 'lengthUnder',
        message: this.generateErrorMessage('validationMessageLengthUnder', 'invalid'),
      });
    }
    if (errors.outOfRange === 'over') {
      errorResponse.push({
        code: 'rangeOver',
        message: this.generateErrorMessage('validationMessageRangeOver', 'invalid'),
      });
    }
    if (errors.outOfRange === 'under') {
      errorResponse.push({
        code: 'rangeUnder',
        message: this.generateErrorMessage('validationMessageRangeUnder', 'invalid'),
      });
    }
    // Pattern matching comes last.
    // Numerical values often have a pattern attribute to bring up the correct on-screen keyboard,
    // but in many cases we'd rather the (more specific) range message be displayed first.
    if (errors.patternMismatch) {
      errorResponse.push({
        code: 'patternMismatch',
        message: this.generateErrorMessage('validationMessagePatternMismatch', 'invalid'),
      });
    }
    if (errors.typeMismatch) {
      errorResponse.push({
        code: 'typeMismatch',
        message: this.generateErrorMessage('validationMessageTypeMismatch', 'invalid'),
      });
    }
    return errorResponse;
  }

  private generateDefaultMessage(key: string) {
    return `${this.label} ${i18n.errors.messages[key]}`;
  }

  private get label() {
    const label = this.data.get('label');
    if (!label) {
      throw new Error(`${this.identifier} should define a label attribute`);
    }
    return label;
  }

  private emitFormFieldValidatedEvent(
    target: FormControlElement,
    valid: boolean,
    errors: IFormFieldError[]
  ) {
    const evt = createCustomEvent('cnf-form-field:validated', {
      errors,
      valid,
    });
    target.dispatchEvent(evt);
  }
}
