import BaseController from 'src/lib/controller/base_controller';
import { dismissAllToasts, dismissSingleToast, showToast } from 'src/lib/overlay/toast';
import { markAsSafeHTML } from 'src/lib/util/safe_html';

const complexToastHTML = `
  <div class="toaster-example__container">
    <h2 class="toaster-example__header">Title</h2>
    <p>This is a toast with HTML content.</p>
  </div>
`;

export default class ToastExamplesController extends BaseController {
  public showSimpleToast() {
    showToast({
      content: markAsSafeHTML('Here is a simple toast notification message.'),
    });
  }

  public showHtmlToast() {
    showToast({
      content: markAsSafeHTML(complexToastHTML),
    });
  }

  public showCustomTimeToast() {
    showToast({
      content: markAsSafeHTML('This toast will hide after 1500 ms.'),
      timeout: 15000,
    });
  }

  public showInifiniteToast() {
    showToast({
      content: markAsSafeHTML('This toast will be here forever (until dismissed).'),
      timeout: Infinity,
    });
  }
  public showRemoteToast() {
    showToast({
      contentHref: '/design-system/test_toast',
      timeout: Infinity,
    });
  }

  public dismissAllToasts() {
    dismissAllToasts();
  }
}
