import { IFormFieldValidationEvent } from 'src/types';

import PurchaseConfiguratorVariantController from 'src/controllers/shopping/purchase_configurator/purchase_configurator_variant_controller';
import { calculateTotalPriceForDisplay } from 'src/helpers/shopping/purchase_configurator_helper';
import i18n from 'src/lib/i18n';
import { createCustomEvent } from 'src/lib/util/create_custom_event';
import PurchaseConfiguratorQuantityExtraInfoController from './extra_info_controller';

interface IDataAttributes {
  fallbackDisplay: string;
  extraChargeMultiplier: string | number;
  priceMultiplier: string | number;
  quantityType: 'float' | 'integer';
  sizeExcludedFromCalculation?: boolean;
  currency: string;
  variantId: number;
}

abstract class BasePurchaseConfiguratorQuantityCalculatorController extends PurchaseConfiguratorVariantController {
  public readonly fieldsetTarget!: HTMLFieldSetElement;
  public readonly quantityTarget!: HTMLInputElement;
  public readonly quantityErrorTarget!: HTMLParagraphElement;
  public readonly sizeTarget!: HTMLInputElement | HTMLSelectElement;
  public readonly hasSizeTarget!: boolean;
  public readonly sizeErrorTarget!: HTMLParagraphElement;
  public readonly hasSizeErrorTarget!: boolean;
  public readonly totalPriceTarget!: HTMLSpanElement;
}

export default class PurchaseConfiguratorQuantityCalculatorController extends (PurchaseConfiguratorVariantController as typeof BasePurchaseConfiguratorQuantityCalculatorController) {
  public static targets = [
    'fieldset',
    'quantity',
    'quantityError',
    'size',
    'sizeError',
    'totalPrice',
  ];

  public onInitialize() {
    this.onConnect(() => {
      this.handleFieldsetInput();
    });

    return super.onInitialize();
  }

  public set isCurrent(isCurrent: boolean) {
    super.isCurrent = isCurrent;
    if (isCurrent) {
      this.enableInputs();
    } else {
      this.disableInputs();
    }
  }

  public handleFieldsetInput() {
    // Hard code something in case the data attr parsing goes belly-up
    let fallbackDisplay = '-';

    try {
      const {
        currency,
        extraChargeMultiplier,
        priceMultiplier,
        quantityType,
        sizeExcludedFromCalculation,
        ...other
      } = this.getRequiredDataAttrAsJSON<IDataAttributes>('quantityData');

      // We can now get the real fallback display to use
      fallbackDisplay = other.fallbackDisplay;

      // Grab the actual values from the UI
      const quantity = this.quantityTarget.value;
      const size = this.hasSizeTarget ? this.sizeTarget.value : undefined;

      // Generate and set the total price
      const totalPrice = calculateTotalPriceForDisplay({
        currency,
        extraChargeMultiplier,
        locale: i18n.locale,
        priceMultiplier,
        quantity,
        quantityType,
        size,
        sizeExcludedFromCalculation,
      });

      this.totalPrice = totalPrice !== null ? totalPrice : fallbackDisplay;

      // Update the info sections, if any
      this.extraInfoControllers.forEach(c =>
        c.updateValue({ quantity, quantityType, size, fallbackDisplay })
      );
    } catch (e) {
      // Ru-roh, something's gone wrong when parsing the attributes
      // TODO handle this error: show the user somehow
      console.warn(e);
      this.totalPrice = fallbackDisplay;
    }
  }

  private set totalPrice(price: string) {
    this.totalPriceTarget.textContent = price;
  }

  private get extraInfoControllers() {
    return this.getSpecificChildControllers<PurchaseConfiguratorQuantityExtraInfoController>(
      PurchaseConfiguratorQuantityExtraInfoController
    );
  }

  private enableInputs() {
    // Rails-UJS will support the automatic removal of input data from within disabled fieldsets soon.
    // For now we need to disable inner inputs manually.
    // We don't care about disabling through the MDC instance, since the inputs will be hidden anyway, so styling changes don't matter.
    this.fieldsetTarget.removeAttribute('disabled');

    // TODO we can remove this hack once Rails has support for not serializing form inputs inside disabled fieldsets
    // https://github.com/rails/rails/pull/36764
    this.quantityTarget.removeAttribute('disabled');
    if (this.hasSizeTarget) {
      this.sizeTarget.removeAttribute('disabled');
    }
  }

  private disableInputs() {
    // Rails-UJS will support the automatic removal of input data from within disabled fieldsets soon.
    // For now we need to disable inner inputs manually.
    // We don't care about disabling through the MDC instance, since the inputs will be hidden anyway, so styling changes don't matter.
    this.fieldsetTarget.setAttribute('disabled', 'disabled');

    // TODO we can remove this hack once Rails has support for not serializing form inputs inside disabled fieldsets
    // https://github.com/rails/rails/pull/36764
    this.quantityTarget.setAttribute('disabled', 'disabled');
    if (this.hasSizeTarget) {
      this.sizeTarget.setAttribute('disabled', 'disabled');
    }
  }
}
