import BaseController from 'src/lib/controller/base_controller';
import Swiper from 'swiper';

export default class CarouselController extends BaseController {
  private carousel?: Swiper = undefined;

  public onInitialize() {
    this.onConnect(() => {
      this.element.classList.remove('cnf-display--none');
      const perView = window.innerWidth > 600 ? 3 : 1.25;
      const desired = this.data.get('slidesPerView');
      this.carousel = new Swiper(this.element as HTMLElement, {
        slidesPerView: desired ? parseFloat(this.data.get('slidesPerView')!) : perView,
        centeredSlides: true,
        spaceBetween: 16,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    });

    return super.onInitialize();
  }
}
