import { MDCMenuSurfaceFoundation } from '@material/menu-surface/index';
import { MDCMenu } from '@material/menu/index';
import BaseController from 'src/lib/controller/base_controller';
import { createCustomEvent } from 'src/lib/util/create_custom_event';
import { MDCMenuItemSelectedEvent } from 'src/types';

const BOTTOM_START = MDCMenuSurfaceFoundation.Corner.BOTTOM_START;

export default class DropdownController extends BaseController {
  public static targets = ['menu'];

  private declare readonly menuTarget: HTMLDivElement;

  private menu: MDCMenu | null = null;

  public onInitialize() {
    this.onConnect(() => {
      const menu = new MDCMenu(this.menuTarget);
      menu.setAnchorCorner(BOTTOM_START);
      menu.listen('MDCMenu:selected', this.dispatchSelectedEvent);

      this.menu = menu;

      return () => {
        menu.unlisten('MDCMenu:selected', this.dispatchSelectedEvent);
        menu.destroy();

        this.menu = null;
      };
    });

    return super.onInitialize();
  }

  public toggleMenu() {
    if (this.menu) {
      this.menu.open = !this.menu.open;
    }
  }

  public openMenu() {
    if (this.menu && !this.menu.open) {
      this.menu.open = true;
    }
  }

  public closeMenu() {
    if (this.menu && this.menu.open) {
      this.menu.open = false;
    }
  }

  private dispatchSelectedEvent = (evt: Event) => {
    const {
      detail: { item, index },
    } = evt as MDCMenuItemSelectedEvent;
    const customEvt = createCustomEvent('cnf-menu:selected', {
      id: this.element.id,
      index,
      item,
    });
    this.element.dispatchEvent(customEvt);
  };
}
