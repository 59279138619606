import ActionCable from 'actioncable';
import BaseController from 'src/lib/controller/base_controller';
import { INotificationData } from 'src/types';

export default class NotificationsController extends BaseController {
  public static targets = ['count'];

  private declare readonly countTarget: HTMLDivElement;

  public onInitialize() {
    const cableConsumer = ActionCable.createConsumer();

    cableConsumer.subscriptions.create(
      {
        channel: 'NotificationsChannel',
      },

      {
        received: (data: INotificationData) => {
          this.handleNewMessage(data);
        },

        connected: () => {
          return;
        },
        disconnected: () => {
          return;
        },
      }
    );

    this.onConnect(() => {
      cableConsumer.connect();

      return () => {
        cableConsumer.disconnect();
      };
    });

    return super.onInitialize();
  }

  public incrementCounter() {
    this.element.classList.add('cnf-messages__topbar-notifications--unread');
    const countContent = this.countTarget.innerText;
    const currentCount = countContent.length === 0 ? 0 : parseInt(countContent, 10);
    this.countTarget.innerText = (currentCount + 1).toString();
  }

  public handleNewMessage(data: INotificationData) {
    this.incrementCounter();
  }
}
