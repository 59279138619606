import { MDCFormField } from '@material/form-field/index';
import { MDCRadio } from '@material/radio/index';
import FormFieldController from 'src/lib/controller/form_field_controller';

// TODO need a radio group for validation.
export default class RadioController extends FormFieldController {
  public static targets = FormFieldController.targets;

  public onInitialize() {
    this.onConnect(() => {
      let disconnector;

      if (this.data.has('ripple')) {
        const radio = new MDCRadio(this.containerTarget);
        const formField = new MDCFormField(this.element);
        formField.input = radio;

        disconnector = () => {
          formField.destroy();
          radio.destroy();
        };
      }

      return disconnector;
    });

    return super.onInitialize();
  }

  protected get invalidClassName() {
    return 'mdc-radio--invalid';
  }
}
