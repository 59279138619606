import ProductNavigationTierController from 'src/controllers/shopping/product_navigation_tier_controller';
import BaseController from 'src/lib/controller/base_controller';
import { stringifyEscapeQuotes } from 'src/lib/util/stringify_escape_quotes';
import trackHover from 'src/lib/util/track_hover';
import { IControllerWithItems, INavigationCategory } from 'src/types';

const ACTIVE_ITEM_CLASS = 'active';
const BACKDROP_CLASS = 'shopping__product-navigation__overlay';
const LIST_SHOWN_CLASS = 'shopping__product-navigation__list--shown';
const supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

export default class ProductNavigationController extends BaseController {
  public static targets = [
    'dropdown',
    'backdrop',
    'navigation',
    'mobileButton',
    'primaryCategoryList',
    'search',
  ];

  private declare readonly dropdownTarget: HTMLElement;
  private declare readonly backdropTarget: HTMLElement;
  private declare readonly navigationTarget: HTMLElement;
  private declare readonly mobileButtonTarget: HTMLElement;
  private declare readonly primaryCategoryListTarget: HTMLElement;
  private declare readonly searchTarget: HTMLElement;

  public items!: INavigationCategory[];

  private tierControllers: ProductNavigationTierController[] = [];
  private backdropActive: boolean = false;
  private currentPath: { target?: HTMLBaseElement; tier: number; key: string }[] = [];

  public onInitialize() {
    this.items = this.parseItems();
    this.onDisconnect(() => {
      this.tierControllers = [];
    });

    this.onChildControllerConnect((controller: BaseController) => {
      if (controller instanceof ProductNavigationTierController) {
        this.tierControllers.push(controller);
      }
    });

    return super.onInitialize();
  }

  public tierSelected(e: Event, tier = 0) {
    const key = this.parseKeyAttribute(e);
    const target = e.target as HTMLBaseElement;
    const same = this.currentPath.length > 0 && this.currentPath[this.currentPath.length - 1].tier === tier;
    if (!same) {
      this.currentPath.push({ target, tier, key });
    }

    if (e.type === 'mouseover' && !supportsTouch) {
      trackHover('mouseleave', target, () => {
        this.selectCategory(tier, key);
        this.itemSelected(tier, target);
      });
    } else if (e.type === 'touchend' && same && !this.isMobile) {
      // If on a tablet device when you tap on the parent category again it will close, however tapping on any other
      // category a second time, then don't prevent default and navigate to that page
      if (tier === 0) {
        e.preventDefault();
        this.closeNavigation();
      }
    } else if (e.type === 'touchend') {
      // This is what happens on a mobile device
      if (this.selectCategory(tier, key).length > 0) {
        e.preventDefault();
        this.itemSelected(tier, target);
      }
    }
  }

  public appendTier(items: INavigationCategory[], parent: INavigationCategory) {
    const tier = this.tierControllers.length + 1;
    if (!items.length) {
      return;
    }
    const htmlClass = !this.isMobile && this.isLastTier(items.slice(0, 9)) ? 'last-tier' : 'regular-tier';
    const tierHtml = `
      <ul class="${htmlClass}"
           data-controller="shopping--product-navigation-tier"
           data-shopping--product-navigation-tier-tier=${tier}
           data-shopping--product-navigation-tier-items='${stringifyEscapeQuotes(items)}'
           data-shopping--product-navigation-tier-parent-category='${stringifyEscapeQuotes(
             parent
           )}'>
      </ul>
    `;
    this.dropdownTarget.insertAdjacentHTML('beforeend', tierHtml);
  }

  public get isMobile() {
    return window.innerWidth < 840;
  }

  public backMenu(e: Event) {
    if (this.currentPath.length == 1) {
      this.closeNavigation();
    } else if (this.currentPath.length > 1) {
      this.currentPath.pop();
      const previous = this.currentPath[this.currentPath.length - 1];
      if (previous!.tier === -1) {
        this.primaryCategoryListTarget.classList.add(LIST_SHOWN_CLASS);
      }
      this.selectCategory(previous!.tier, previous!.key);
    }
  }

  public showSearch(e: Event) {
    this.closeNavigation();
    this.searchTarget.classList.toggle(LIST_SHOWN_CLASS);
    setImmediate(() => {
      this.searchTarget.getElementsByTagName('input')[0].focus();
    });
  }

  public hideSearch() {
    this.searchTarget.classList.remove(LIST_SHOWN_CLASS);
  }

  public showMenu(e: Event) {
    this.hideSearch();
    if (this.currentPath.length > 0) {
      this.closeNavigation();
    } else {
      this.currentPath.push({ tier: -1, key: '' });
      this.primaryCategoryListTarget.classList.add(LIST_SHOWN_CLASS);
      this.addBackdrop();
    }
  }

  public isLastTier(items: INavigationCategory[]) {
    if (items.find(o => o.i.length !== 0)) {
      return false;
    }
    return true;
  }

  public closeNavigation(): any {
    this.backdropTarget.classList.remove(BACKDROP_CLASS);
    this.primaryCategoryListTarget.classList.remove(LIST_SHOWN_CLASS);
    this.clearCategoriesTo(0);
    this.clearPreviousActiveItem(0);
    this.backdropActive = false;
    this.currentPath = [];
  }

  public closeNavigationWithTimeout() {
    trackHover('mouseenter', this.navigationTarget, this.closeNavigation.bind(this));
  }

  private itemSelected(tier: number, target: HTMLElement) {
    this.toggleActive(tier, target);
    this.primaryCategoryListTarget.classList.remove(LIST_SHOWN_CLASS);
    this.addBackdrop();
  }

  private selectCategory(tier: number, key: string | null): INavigationCategory[] {
    if (key == null) {
      this.clearCategoriesTo(0);
      return [];
    }

    this.clearCategoriesTo(tier);
    const emittedController = this.controllerOfTier(tier);
    if (emittedController !== undefined) {
      const items = emittedController.items;
      const category = items.find(el => el.k === key);

      if (category !== undefined) {
        this.appendTier(category.i, category);
        return category.i;
      }
    }
    return [];
  }

  private clearPreviousActiveItem(tier: number) {
    let activeItem = null;

    const tierTarget = this.controllerOfTier(tier);
    if (tierTarget !== null && tierTarget !== undefined) {
      activeItem = tierTarget.element.getElementsByClassName(ACTIVE_ITEM_CLASS);

      if (activeItem !== null && activeItem[0]) {
        activeItem[0].classList.remove(ACTIVE_ITEM_CLASS);
      }
    }
  }

  private toggleActive(tier: number, target: HTMLElement | null): void {
    this.clearPreviousActiveItem(tier);

    if (target !== null && target.parentElement !== null) {
      const targetElement = tier === 0 ? target : target.parentElement;
      if (targetElement.classList.contains(ACTIVE_ITEM_CLASS)) {
        targetElement.classList.remove(ACTIVE_ITEM_CLASS);
      } else {
        targetElement.classList.add(ACTIVE_ITEM_CLASS);
      }
    }
  }

  private elementIsActive(element: HTMLBaseElement) {
    return element.classList.contains(ACTIVE_ITEM_CLASS);
  }

  private clearCategoriesTo(tier: number): void {
    this.tierControllers.slice(tier).forEach(c => {
      if (c.element !== null && c.element.parentNode !== null) {
        c.element.parentNode.removeChild(c.element);
      }
      this.tierControllers.pop();
    });
  }

  private controllerOfTier(tier: number): IControllerWithItems {
    if (tier === 0) {
      return this;
    }

    return this.tierControllers[tier - 1] as IControllerWithItems;
  }

  private parseItems(): INavigationCategory[] {
    const itemsStr = this.data.get('categories');

    if (itemsStr !== null) {
      return JSON.parse(itemsStr) as INavigationCategory[];
    }

    return [] as INavigationCategory[];
  }

  private parseKeyAttribute(e: Event): string {
    if (e !== null && e !== undefined) {
      const target = e.target as HTMLBaseElement;
      return target.getAttribute('key') || '';
    }

    return '';
  }

  private addBackdrop() {
    if (this.backdropActive === true) {
      return;
    }
    this.backdropTarget.classList.add(BACKDROP_CLASS);
    this.backdropActive = true;
  }
}
