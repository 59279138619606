import BaseController from 'src/lib/controller/base_controller';
import { replaceContentsWithChildren } from 'src/lib/util/replace_with_dom_nodes';
import {SnackbarShowEvent} from 'src/types';

const INITIAL_CLASS = 'cnf-flash-message-bar--initially-invisible';
const VISIBLE_CLASS = 'cnf-flash-message-bar--visible';
const COLLAPSED_CLASS = 'cnf-flash-message-bar--collapsed';
const TEXT_CLASS = 'mdc-typography--subtitle1';

export default class FlashController extends BaseController {
  public onInitialize() {
    this.onConnect(() => {
      if (this.data.get('showInitial')) {
        this.reveal();
      }
    });

    return super.onInitialize();
  }

  public handleCloseEvent() {
    this.hide();
  }

  // Reveal by calling:
  // window.dispatchEvent(new CustomEvent('cnf-flash:show', { detail: { message } }));
  public async handleShowEvent(evt: Event) {
    const {
      detail: { message, timeout },
    } = evt as SnackbarShowEvent;
    this.showFlashMessage(message, timeout);
  }

  public get isRevealed() {
    return this.element.classList.contains(VISIBLE_CLASS);
  }

  public set text(text: string) {
    const p = document.createElement('p');
    p.classList.add(TEXT_CLASS);
    p.textContent = text;
    this.content = p;
  }

  public renderHeadingWithListContent(heading: string, items: string[]) {
    const headingNode = this.createHeading(heading);

    const listNode = document.createElement('ul');
    items.forEach(item => {
      const listItemNode = document.createElement('li');
      listItemNode.textContent = item;
      listNode.appendChild(listItemNode);
    });

    this.content = [headingNode, listNode];
  }

  public showFlashMessage = (text: string, timeout?: number) => {
    this.text = text;
    this.reveal(timeout);
  };

  public reveal = (timeout?: number) => {
    this.removeInitialClass();
    this.element.classList.add(VISIBLE_CLASS);
    this.element.classList.remove(COLLAPSED_CLASS);
    if (timeout) {
      setTimeout(this.hide, timeout);
    }
  };

  public hide = () => {
    this.element.classList.remove(VISIBLE_CLASS);
  };

  public toggle = () => {
    this.removeInitialClass();
    this.element.classList.toggle(VISIBLE_CLASS);
  };

  private removeInitialClass() {
    this.element.classList.remove(INITIAL_CLASS);
  }

  private createHeading(content: string) {
    const heading = document.createElement('h2');
    heading.classList.add('mdc-typography--headline7');
    heading.classList.add('cnf-flash-message-bar__heading');
    heading.textContent = content;
    return heading;
  }

  private set content(children: Node | Node[]) {
    replaceContentsWithChildren(this.element, children);
  }
}
