import BaseController from 'src/lib/controller/base_controller';
import { showDialog } from 'src/lib/overlay/dialog.ts';
import { markAsSafeHTML } from 'src/lib/util/safe_html';

export default class DialogExamplesController extends BaseController {
  public showAlertDialog() {
    showDialog({
      actions: [
        {
          action: 'close',
          title: 'Awesome',
        },
      ],
      content: markAsSafeHTML(`Chuck Norris can compile syntax errors.`),
      onClosing: () => console.log('dialog closed.'),
      title: 'An alert',
    });
  }

  public showConfirmDialog() {
    showDialog({
      actions: [
        {
          action: 'cancel',
          title: 'No way!',
        },
        {
          action: 'ok',
          title: 'Awesome',
        },
      ],
      content: markAsSafeHTML(`When Chuck Norris claps his hands, the sun turns off.`),
      defaultAction: 'ok',
      onClosing: e => console.log('dialog closed with reason: ' + e.detail.closeReason),
      title: 'Confirm',
    });
  }

  public showSimpleDialog() {
    showDialog({
      content: markAsSafeHTML(`Simple dialogs have no action buttons`),
      title: 'A simple dialog',
    });
  }

  public showRemoteContentDialog() {
    showDialog({
      contentHref: '/design-system/test_fragment',
      title: 'The example.com page content dialog',
    });
  }

  public showRemoteContentDialogWithPlaceholder() {
    showDialog({
      contentHref: '/design-system/test_fragment',
      placeholder: markAsSafeHTML(`<h1>...</h1><p>...</p>`),
      title: 'The example.com page content dialog',
    });
  }
}
