import axios from 'axios';

const csrfMetaTag = document.head.querySelector('[name="csrf-token"]');
const csrfToken = (csrfMetaTag && csrfMetaTag.getAttribute('content')) || '';

export default axios.create({
  headers: {
    common: {
      'X-CSRF-TOKEN': csrfToken,
    },
  },
});
