import axios from 'src/lib/axios';
import BaseController from 'src/lib/controller/base_controller';
import i18n from 'src/lib/i18n';
import { markAsSafeHTML, safelySetInnerHTML } from 'src/lib/util/safe_html';
import {
  IHTMLElementAnimationEndEvent,
  ISafeHTMLContent,
  ToastDismissEvent,
  ToastShowEvent,
} from 'src/types';

type NotificationElement = HTMLLIElement;

const DOM_NAMESPACE = 'cnf-toaster';

const NOTIFICATION_CLASSNAME = `${DOM_NAMESPACE}__notification`;
const NOTIFICATION_ACTIVE_CLASSNAME = `${NOTIFICATION_CLASSNAME}--active`;
const NOTIFICATION_DISMISSED_CLASSNAME = `${NOTIFICATION_CLASSNAME}--dismissed`;

const DEFAULT_DISMISS_AFTER_MS = 3000;
const DISMISS_ALL_STAGGER_MS = 50;

export default class ToasterController extends BaseController {
  public async handleShowEvent(evt: ToastShowEvent) {
    const {
      detail: { content, timeout, contentHref },
    } = evt;
    const notification = await this.createNotification(content, contentHref);
    const toastTimeout = timeout || DEFAULT_DISMISS_AFTER_MS;
    if (toastTimeout !== Infinity) {
      const timerId = window.setTimeout(
        () => this.dismissNotification(notification.id),
        toastTimeout
      );
      notification.dataset.dismissTimerId = timerId.toString();
    }
    // .prepend() not available in IE11
    // this.element.prepend(notification);
    this.element.insertBefore(notification, this.element.firstChild);

    notification.addEventListener('click', () => {
      this.dismissNotification(notification.id);
    });

    notification.addEventListener('touchend', () => {
      this.dismissNotification(notification.id);
    });

    // Ensure fadeIn happens on next paint
    window.setTimeout(() => notification.classList.add(NOTIFICATION_ACTIVE_CLASSNAME), 50);
  }

  public handleDismissAllEvent() {
    // This isn't exactly the 'stimulus way' - we should probably be using targets instead.
    // But, it works.
    Array.from(this.element.getElementsByClassName(NOTIFICATION_CLASSNAME))
      .reverse() // Bottom notification should disappear first
      .forEach((node, idx) => {
        const el = node as NotificationElement;
        clearTimeout(+el.dataset.dismissTimerId!);
        setTimeout(() => this.dismissNotification(el.id), idx * DISMISS_ALL_STAGGER_MS);
      });
  }

  public handleDismissSingleEvent(evt: ToastDismissEvent) {
    const {
      detail: { id },
    } = evt;
    this.dismissNotification(id);
  }

  public handleAnimationEnd({ target, animationName }: IHTMLElementAnimationEndEvent) {
    if (
      animationName === 'cnf-toaster__bounce-out' &&
      target !== null &&
      target.id.startsWith(DOM_NAMESPACE) &&
      target.classList.contains(NOTIFICATION_DISMISSED_CLASSNAME)
    ) {
      // .remove() not available in IE11
      // target.remove();
      this.element.removeChild(target);
    }
  }

  private nextNotificationId() {
    const raw = this.data.get('currentNotificationId');
    if (raw === null) {
      throw new Error('currentNotificationId data attribute is not present');
    }

    const parsed = parseInt(raw, 10);
    const next = parsed + 1;

    this.data.set('currentNotificationId', `${next}`);
    return `${DOM_NAMESPACE}-${next}`;
  }

  private async createNotification(
    content: ISafeHTMLContent | undefined,
    contentHref: string | undefined
  ): Promise<NotificationElement> {
    const notification = document.createElement('li');
    notification.id = this.nextNotificationId();
    notification.classList.add(NOTIFICATION_CLASSNAME);

    if (contentHref) {
      // TODO: What if contentHref already has a query string parameters?
      const remoteContent = await this.getRemoteContent(
        `${contentHref}?toast_id=${notification.id}`
      );
      safelySetInnerHTML(notification, remoteContent);
    } else if (content) {
      safelySetInnerHTML(notification, content);
    }

    return notification;
  }

  private getRemoteContent(contentHref: string): Promise<ISafeHTMLContent> {
    return this.getContent(contentHref).catch(err => {
      console.warn(err);
      const errorMessage = i18n.errors.generic_server_error;
      return markAsSafeHTML(errorMessage);
    });
  }

  private getContent(url: string): Promise<ISafeHTMLContent> {
    return axios
      .get<string>(url, {
        headers: {
          'Content-Type': 'text/html',
        },
      })
      .then(response => markAsSafeHTML(response.data));
  }

  private dismissNotification(notificationId: string) {
    // Only try to dismiss the notification if it is still in the DOM,
    // and it is not being dismissed
    const notification = document.getElementById(notificationId);
    if (notification && notification.classList.contains(NOTIFICATION_ACTIVE_CLASSNAME)) {
      notification.classList.add(NOTIFICATION_DISMISSED_CLASSNAME);
      notification.classList.remove(NOTIFICATION_ACTIVE_CLASSNAME);
    }
  }
}
