import BaseController from 'src/lib/controller/base_controller';
import { INavigationCategory } from 'src/types';
import ProductNavigationController from './product_navigation_controller';

export default class ProductNavigationTierController extends BaseController {
  public items = [] as INavigationCategory[];
  private tier = 1;
  private parentController!: ProductNavigationController;
  private parentCategory!: INavigationCategory;

  public onInitialize() {
    this.onConnect(() => {
      this.parentController = this.parseParentController();
      this.tier = this.parseTier();
      this.items = this.parseItems();
      this.parentCategory = this.parseParentCategory();

      this.render();

      return () => {
        if (this.element !== null && this.element.parentNode !== null) {
          this.element.parentNode.removeChild(this.element);
        }
      };
    });

    return super.onInitialize();
  }

  public itemHovered(e: Event) {
    this.parentController.tierSelected(e, this.tier);
  }

  public get isMobile() {
    return window.innerWidth < 840;
  }

  private render(): void {
    let itemsToRender = this.items.filter((i) => i.s);

    if (this.isMobile) {
      const seeAllHtml = `
        <li class="mdc-typography--body2 cnf-typography--regular">
          <a class="mdc-theme--text-secondary-on-background" data-action="click->shopping--product-navigation#backMenu">&lt; Back</a>
        </li>
        <li class="mdc-typography--body2 cnf-typography--medium">
          <a class="mdc-theme--text-secondary-on-background" href=${this.parentCategory.u}>See all ${this.parentCategory.n}</a>
        </li>
      `;
      this.element.insertAdjacentHTML('beforeend', seeAllHtml);
      this.renderItems(itemsToRender);
      return;
    }
    // Truncate to first 9 items if there are parent categories present & add placeholder
    if (itemsToRender.length > 9 && itemsToRender.find(el => el.sic > 0) !== undefined) {
      itemsToRender = itemsToRender.slice(0, 9);
      this.renderItems(itemsToRender);

      const seeAllHtml = `
        <li class="see-all mdc-typography--body2 cnf-typography--light">
          <a href=${this.parentCategory.u}>See all</a>
        </li>
      `;

      this.element.insertAdjacentHTML('beforeend', seeAllHtml);
      return;
    }

    this.renderItems(itemsToRender.slice(0, 9));

    if (itemsToRender.length > 10) {
      this.parentController.appendTier(this.items.slice(9), this.parentCategory);
    }
  }

  private renderItems(items: INavigationCategory[]) {
    items.forEach(item => {
      let arrowHtml = '';
      let actionHtml = '';

      if (item.sic > 0) {
        arrowHtml = '<i class="material-icons">keyboard_arrow_right</i>';
        actionHtml = `mouseover->shopping--product-navigation-tier#itemHovered
          touchend->shopping--product-navigation-tier#itemHovered
          ${this.tier}Selected click->shopping--product-navigation-tier#itemHovered`;
      }

      const itemHtml = `
        <li class ="mdc-typography--body2 cnf-typography--regular">
          <a class="mdc-theme--text-secondary-on-background"
              data-action="${actionHtml}" key="${item.k}" href="${item.u}" ${
        item.e ? 'target="_blank"' : ''
      }>${item.n}</a>
          ${arrowHtml}
        </li>
      `;

      this.element.insertAdjacentHTML('beforeend', itemHtml);
    });
  }

  private parseParentCategory(): INavigationCategory {
    const categoryStr = this.data.get('parentCategory');

    if (categoryStr !== null) {
      return JSON.parse(categoryStr) as INavigationCategory;
    }
    return {} as INavigationCategory;
  }

  private parseParentController(): ProductNavigationController {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === 'shopping--product-navigation';
    }) as ProductNavigationController;
  }

  private parseItems(): INavigationCategory[] {
    const itemsStr = this.data.get('items');

    if (itemsStr !== null) {
      return JSON.parse(itemsStr) as INavigationCategory[];
    }
    return [] as INavigationCategory[];
  }

  private parseTier(): number {
    return parseInt(this.data.get('tier') as string, 10);
  }
}
