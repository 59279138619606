import BaseController from 'src/lib/controller/base_controller';
import { removeHashesFromQueryString } from 'src/lib/util/add_parameter_to_url';
import { serialize } from 'src/lib/util/serialize';

export default class SidebarFiltersController extends BaseController {
  public static targets = ['form'];

  private declare readonly formTarget: HTMLFormElement;

  // Override reseting other parameters on form submit via GET method - build request url explicitly
  public submit(e: Event) {
    e.preventDefault();

    const serializedForm = serialize(this.formTarget);

    // reset query string parameters
    const queryString = removeHashesFromQueryString(
      window.location.search,
      'ft',
      'utf8'
    );

    window.location.search = queryString + '&' + serializedForm;
  }
}
