import { MDCDismissibleDrawerFoundation, MDCDrawer } from '@material/drawer/index';
import BaseController from 'src/lib/controller/base_controller';

const ALLOWED_CLASSES = [
  MDCDismissibleDrawerFoundation.cssClasses.MODAL,
  MDCDismissibleDrawerFoundation.cssClasses.DISMISSIBLE,
];

export default class DrawerController extends BaseController {
  private drawer: MDCDrawer | null = null;

  private get hasAllowedClassName() {
    return ALLOWED_CLASSES.some(className => this.element.classList.contains(className));
  }

  public onInitialize() {
    this.onConnect(() => {
      if (!this.hasAllowedClassName) {
        return;
      }

      const drawerMDC = new MDCDrawer(this.element);
      this.drawer = drawerMDC;

      return () => {
        drawerMDC.destroy();
        this.drawer = null;
      };
    });

    return super.onInitialize();
  }

  public toggle() {
    if (this.drawer) {
      this.drawer.open = !this.drawer.open;
    }
  }
}
