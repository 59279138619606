import BaseController from 'src/lib/controller/base_controller';
import { createCustomEvent } from 'src/lib/util/create_custom_event';

export default class BreakdownRemoveItemController extends BaseController {
  public static targets = ['input'];

  private declare readonly inputTarget: HTMLInputElement;

  public handleRemoveIconClick() {
    this.toggleInput();
    this.dispatchRemoveEvent();
  }

  private get rowId() {
    return this.getRequiredDataAttr('rowId');
  }

  private dispatchRemoveEvent() {
    const evt = createCustomEvent('breakdown-form:disable-toggled', {
      rowId: this.rowId,
    });
    this.element.dispatchEvent(evt);
  }

  private toggleInput() {
    if (this.inputTarget.value === 'true') {
      this.inputTarget.value = 'false';
    } else {
      this.inputTarget.value = 'true';
    }
  }
}
