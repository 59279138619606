// import { MDCSelect } from '@material/select/index';

import { ISafeHTMLContent } from 'src/types';

import FormFieldController from 'src/lib/controller/form_field_controller';
import { createCustomEvent } from 'src/lib/util/create_custom_event';
import { markAsSafeHTML, safelySetInnerHTML } from 'src/lib/util/safe_html';

export default class SelectController extends FormFieldController {
  public static targets = FormFieldController.targets;

  public onInitialize() {
    this.onConnect(() => {
      this.nativeControl.addEventListener('change', this.handleChangeEvent);
      return () => {
        this.nativeControl.removeEventListener('change', this.handleChangeEvent);
      };
    });

    return super.onInitialize();
  }

  public set disabled(disabled: boolean) {
    this.confinusSelectElement.classList.toggle(this.disabledClassName, disabled);
    this.nativeControl.disabled = disabled;
  }

  public set options(options: ISafeHTMLContent) {
    safelySetInnerHTML(this.nativeControl, options);
  }

  public get emptyOption() {
    return '<option value="" selected>Select a category</option>';
  }

  public get value(): string {
    return this.nativeControl.value;
  }

  public empty() {
    this.options = markAsSafeHTML(this.emptyOption);
  }

  protected get invalidClassName() {
    return 'cnf-select--invalid';
  }

  protected get disabledClassName() {
    return 'cnf-select--disabled';
  }

  private get nativeControl() {
    return this.element.getElementsByClassName(
      'cnf-select__native-control'
    )[0] as HTMLSelectElement;
  }

  private get confinusSelectElement() {
    return this.element.getElementsByClassName('cnf-select')[0] as HTMLDivElement;
  }

  private handleChangeEvent = (evt: Event) => {
    const name = this.data.get('name');
    const target = evt.target as HTMLSelectElement;
    const customEvt = createCustomEvent('cnf-select:change', {
      name,
      index: target.selectedIndex,
      value: target.value,
    });
    this.element.dispatchEvent(customEvt);
  };
}
