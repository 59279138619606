import { MDCRipple } from '@material/ripple/index';
import BaseController from 'src/lib/controller/base_controller';

export enum THEME {
  Primary,
  Secondary,
}

export enum VARIANT {
  Contained,
  Raised,
  Outlined,
  Text,
}

const VARIANT_CLASS_NAMES = {
  Contained: ['mdc-button--unelevated', 'cnf-button--filled', 'cnf-button--unelevated'],
  Outlined: ['mdc-button--outlined', 'cnf-button--outlined'],
  Raised: ['mdc-button--raised', 'cnf-button--filled', 'cnf-button--raised'],
  Text: ['cnf-button--text'],
};

const THEME_CLASS_NAMES = {
  Primary: ['cnf-button--primary'],
  Secondary: ['cnf-button--secondary'],
};

const ALL_THEME_CLASS_NAMES = Object.values(THEME_CLASS_NAMES).flatMap(v => v);
const ALL_VARIANT_CLASS_NAMES = Object.values(VARIANT_CLASS_NAMES).flatMap(v => v);

export default class ButtonController extends BaseController {
  public onInitialize() {
    this.onConnect(() => {
      let disconnector;
      if (this.data.has('ripple')) {
        const buttonMDC = new MDCRipple(this.element);
        disconnector = () => {
          buttonMDC.destroy();
        };
      }

      return disconnector;
    });

    return super.onInitialize();
  }

  public set theme(t: THEME) {
    this.removeClasses(ALL_THEME_CLASS_NAMES);

    switch (t) {
      case THEME.Primary:
        this.applyClasses(THEME_CLASS_NAMES.Primary);
        break;
      case THEME.Secondary:
        this.applyClasses(THEME_CLASS_NAMES.Secondary);
        break;
    }
  }

  public set variant(v: VARIANT) {
    this.removeClasses(ALL_VARIANT_CLASS_NAMES);

    switch (v) {
      case VARIANT.Contained:
        this.applyClasses(VARIANT_CLASS_NAMES.Contained);
        break;
      case VARIANT.Raised:
        this.applyClasses(VARIANT_CLASS_NAMES.Raised);
        break;
      case VARIANT.Outlined:
        this.applyClasses(VARIANT_CLASS_NAMES.Outlined);
        break;
      case VARIANT.Text:
        this.applyClasses(VARIANT_CLASS_NAMES.Text);
        break;
    }
  }

  public set disabled(disabled: boolean) {
    if (disabled) {
      this.element.setAttribute('disabled', 'disabled');
    } else {
      this.element.removeAttribute('disabled');
    }
  }

  public applyClasses(classNames: string[]) {
    // IE11 don't support multiple params in classList.add() func
    classNames.forEach(cn => this.element.classList.add(cn));
  }

  public removeClasses(classNames: string[]) {
    // IE11 don't support multiple params in classList.remove() func
    classNames.forEach(cn => this.element.classList.remove(cn));
  }
}
