import flatpickr from 'flatpickr';
import { Instance } from 'flatpickr/dist/types/instance';
import {
  buildFlatpickrOptions,
  IDataAttributes,
} from 'src/helpers/shopping/delivery_date_calendar_helper';
import BaseController from 'src/lib/controller/base_controller';

export default class DeliveryDateCalendarController extends BaseController {
  public static targets = ['datepicker'];

  private declare readonly datepickerTarget: HTMLInputElement;

  private datePicker!: Instance | null;

  public onInitialize() {
    this.onConnect(() => {
      const dataAttributes = this.getRequiredDataAttrAsJSON<IDataAttributes>('data');

      // Build flatpickr instance
      const flatpickrOpts = buildFlatpickrOptions(dataAttributes);
      this.datePicker = flatpickr(this.datepickerTarget, flatpickrOpts);

      return () => {
        this.datePicker!.destroy();
        this.datePicker = null;
      };
    });

    return super.onInitialize();
  }

  public open() {
    if (this.datePicker) {
      this.datePicker!.open();
    }
  }

  public close() {
    if (this.datePicker) {
      this.datePicker!.destroy();
    }
  }
}
