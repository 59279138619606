import BaseController from 'src/lib/controller/base_controller';

export default class AssigneeController extends BaseController {
  public static targets = ['form'];

  private declare readonly formTarget: HTMLFormElement;

  public submitForm() {
    this.formTarget.submit();
  }
}
