import CheckboxController from 'src/controllers/forms/checkbox_controller';
import FormFieldController from 'src/lib/controller/form_field_controller';

export default class CheckboxCollectionController extends FormFieldController {
  public static targets = ['showMoreLink'];

  private declare readonly showMoreLinkTarget: HTMLElement;

  private showingMore = false;

  public onInitialize() {
    this.onChildControllerConnect((checkbox) => {
      if (checkbox instanceof CheckboxController) {
        const classList = checkbox.element.classList;
        if (classList.contains('cnf-checkbox-collection--hidden')) {
          if (checkbox.checked) {
            classList.remove('cnf-display--none');
          }
        }
      }
    });

    return super.onInitialize();
  }

  public validate() {
    if (!this.data.get('required')) {
      return {
        errors: [],
        valid: true,
      };
    }
    return {
      errors: [
        {
          code: 'missingValue',
          message: this.generateErrorMessage('validationMessageRequired', 'blank'),
        },
      ],
      valid: !!this.checkboxControllers.find(field => field.checked),
    };
  }

  public showMore() {
    this.checkboxControllers.forEach((checkbox) => {
      const classList = checkbox.element.classList;
      if (classList.contains('cnf-checkbox-collection--hidden')) {
        if (checkbox.checked) {
          classList.remove('cnf-display--none');
        } else {
          classList.toggle('cnf-display--none');
        }
      }
    });
    this.showingMore = !this.showingMore;
    this.showMoreLinkTarget.innerText = this.showingMore ? 'Show less...' : 'Show more...';
  }

  protected get invalidClassName() {
    return 'mdc-checkbox--invalid';
  }

  private get checkboxControllers() {
    return this.getSpecificChildControllers<CheckboxController>(CheckboxController);
  }
}
