import BaseController from 'src/lib/controller/base_controller';

const BASE_CLASSNAME = 'cnf-drawer__sub-menu';

export default class DrawerSubMenuController extends BaseController {
  public toggleAccordion() {
    for (const d of document.getElementsByClassName(BASE_CLASSNAME)) {
      if (d != this.element) {
        d.classList.remove(`${BASE_CLASSNAME}--active`);
      }
    }
    this.element.classList.toggle(`${BASE_CLASSNAME}--active`);
  }
}
