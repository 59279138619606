import BaseController from 'src/lib/controller/base_controller';
import { showDialog } from 'src/lib/overlay/dialog';

export default class RefreshPopupController extends BaseController {
  public showRefreshPopup() {
    showDialog({
      contentHref: '/marketplace/reminders/refresh-page',
    });
  }
}
