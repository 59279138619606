import { MDCTextField } from '@material/textfield/index';
import FormFieldController from 'src/lib/controller/form_field_controller';

export default class TextAreaController extends FormFieldController {
  public static targets = FormFieldController.targets;

  public onInitialize() {
    this.onConnect(() => {
      const textareaMDC = new MDCTextField(this.containerTarget);

      // Turn off native form validation. We'll be handling this ourselves.
      textareaMDC.useNativeValidation = false;

      return () => {
        textareaMDC.destroy();
      };
    });

    return super.onInitialize();
  }

  protected get invalidClassName() {
    return 'mdc-text-field--invalid';
  }
}
