import BaseController from 'src/lib/controller/base_controller';
import { showSnackbar } from 'src/lib/overlay/snackbar';

export default class ClickToCopyController extends BaseController {
  public copy() {
    this.selectText();
    showSnackbar({ message: 'Copied!', timeout: 1000 });
  }

  private selectText() {
    const range = document.createRange();
    range.selectNodeContents(this.element);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    document.execCommand('copy');
    selection.removeAllRanges();
  }
}
