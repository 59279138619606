import DeliveryDateCalendarController from 'src/controllers/shopping/delivery_date_calendar_controller';
import BaseController from 'src/lib/controller/base_controller';

export default class DeliveryDateSelectController extends BaseController {
  public static targets = ['form'];

  private declare readonly formTarget: HTMLFormElement;

  public showCalendar(evt: Event) {
    const calendarController = this.getSpecificChildController<DeliveryDateCalendarController>(
      DeliveryDateCalendarController
    );
    calendarController!.open();
  }

  public dateSelected(evt: Event) {
    evt.stopPropagation();
    this.formTarget.submit();
  }
}
