import BaseController from 'src/lib/controller/base_controller';
import { showDialog } from 'src/lib/overlay/dialog.ts';
import { markAsSafeHTML } from 'src/lib/util/safe_html';

export default class ProductLightboxController extends BaseController {
  public handleButtonClick(evt: Event) {
    // Stop propagation: prevent any clicks leaking out
    evt.preventDefault();
    evt.stopPropagation();

    // Show the dialog
    const [contentHref, placeholder] = this.getRequiredDataAttrs('contentHref', 'placeholder');
    showDialog({
      contentHref,
      placeholder: markAsSafeHTML(placeholder),
    });
  }
}
