import BaseController from 'src/lib/controller/base_controller';
import { showDialog } from 'src/lib/overlay/dialog.ts';

export default class ListItemController extends BaseController {
  public openDialog() {
    const id = this.data.get('id');
    if (id == null) {
      return;
    }

    showDialog({
      actions: [
        {
          action: 'cancel',
          title: 'OK',
        },
      ],
      contentHref: `/platform/reports/audit_logs/${id}`,
      title: `System activity number ${id} logged`,
    });
  }
}
