import BaseController from 'src/lib/controller/base_controller';

import DrawerController from 'src/controllers/base/drawer_controller';

export default class NavigableController extends BaseController {
  public handleNavButtonClick(evt: Event) {
    const drawerController = this.getSpecificChildController<DrawerController>(DrawerController);
    if (drawerController) {
      evt.preventDefault();
      drawerController.toggle();
    }
  }
}
