import BaseController from 'src/lib/controller/base_controller';
import {
  addParametersToQueryString,
  removeParameterFromQueryString,
} from 'src/lib/util/add_parameter_to_url';
import { Keys, keyWasPressed } from 'src/lib/util/keyboard_key';
import { MDCSelectChangeEvent } from 'src/types';
import flatpickr from 'flatpickr';
import { Instance } from 'flatpickr/dist/types/instance';

export default class DataFilterFormController extends BaseController {
  public handleFilterChange({ detail: { name, value } }: MDCSelectChangeEvent) {
    this.reloadWithQueryParam(name, value);
  }

  public handleCheckedFilterChange(evt: Event) {
    const { name, checked } = evt.target as HTMLInputElement;
    this.reloadWithQueryParam(name, checked ? 'true' : undefined);
  }

  public handleToggleGrid() {
    this.reloadWithQueryParam('table_mode', this.data.get('grid') === 'true' ? 'table' : 'grid');
  }

  public handleRangePicker(evt: Event) {
    const datePicker = flatpickr(evt.target as HTMLInputElement, {
      mode: 'range',
      onClose: (selectedDates: Date[], dateStr: string, instance: Instance) => {
        if (selectedDates.length == 2) {
          this.reloadWithQueryParam(
            'custom_range',
            selectedDates
              .map((d) => {
                return instance.formatDate(new Date(d), 'Y-m-d');
              })
              .join('_')
          );
        }
      },
    });
    datePicker.open();
  }

  // https://medium.com/@uistephen/keyboardevent-key-for-cross-browser-key-press-check-61dbad0a067a
  public handleSearchInputKeydown(evt: KeyboardEvent) {
    if (evt.defaultPrevented) {
      return;
    }
    const enterPressed = keyWasPressed(evt, Keys.Enter);
    if (enterPressed) {
      // Stop form submission
      evt.preventDefault();

      // Take the name/value pair from the input and set on the query string
      const { name, value } = evt.target as HTMLInputElement;
      this.reloadWithQueryParam(name, value);
    }
  }

  private reloadWithQueryParam(name: string, value?: string) {
    if (value === undefined) {
      window.location.search = removeParameterFromQueryString(window.location.search, name);
    } else {
      const params = {
        [name]: value,
      };
      window.location.search = addParametersToQueryString(window.location.search, params);
    }
  }
}
