import ButtonController, { THEME, VARIANT } from 'src/controllers/base/button_controller';
import BaseController from 'src/lib/controller/base_controller';
import FormFieldController from 'src/lib/controller/form_field_controller';

const SELECTED_BUTTON_CLASS_NAME = 'cnf-button-group__button--selected';

export default class ButtonRadioGroupController extends FormFieldController {
  public static targets = FormFieldController.targets;

  public handleChange(evt: Event) {
    this.buttonControllers.forEach(bc => {
      if (bc.element === evt.currentTarget) {
        // This button should be selected.
        bc.theme = THEME.Primary;
        bc.variant = VARIANT.Contained;
        bc.applyClasses([SELECTED_BUTTON_CLASS_NAME]);
      } else {
        // This is a deselected button.
        bc.theme = THEME.Secondary;
        bc.variant = VARIANT.Outlined;
        bc.removeClasses([SELECTED_BUTTON_CLASS_NAME]);
      }
    });
    // We want to change the selected button.
  }

  protected get invalidClassName() {
    return 'cnf-button-group--invalid';
  }

  private get buttonControllers(): ButtonController[] {
    return this.getSpecificChildControllers<ButtonController>(ButtonController);
  }
}
