import { MDCTopAppBar } from '@material/top-app-bar/index';
import BaseController from 'src/lib/controller/base_controller';

export default class TopAppBarController extends BaseController {
  public onInitialize() {
    this.onConnect(() => {
      const topAppBarMDC = new MDCTopAppBar(this.element);
      return () => {
        topAppBarMDC.destroy();
      };
    });

    return super.onInitialize();
  }
}
