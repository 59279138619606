import { MDCSnackbar } from '@material/snackbar/index';

import BaseController from 'src/lib/controller/base_controller';
import { replaceContentsWithChildren } from 'src/lib/util/replace_with_dom_nodes';

import { SnackbarShowEvent } from 'src/types';

const DEFAULT_TIMEOUT = 6000;

export default class SnackbarController extends BaseController {
  public static targets = ['label', 'actionsContainer'];

  private declare readonly labelTarget: HTMLDivElement;
  private declare readonly actionsContainerTarget: HTMLDivElement;

  private snackbarMDC: MDCSnackbar | null = null;

  public onInitialize() {
    this.onConnect(() => {
      const snackbarMDC = new MDCSnackbar(this.element);
      this.snackbarMDC = snackbarMDC;

      if (this.data.get('showInitial')) {
        this.reveal();
      }

      return () => {
        snackbarMDC.destroy();
        this.snackbarMDC = null;
      };
    });

    return super.onInitialize();
  }

  public handleShowEvent(evt: SnackbarShowEvent) {
    const {
      detail: { message, actionText, actionHandler, timeout },
    } = evt;
    if (message) {
      // Setup our DOM structure
      this.labelTarget.textContent = message;
      if (actionText) {
        const actionButton = this.createActionButton(actionText, actionHandler);
        replaceContentsWithChildren(this.actionsContainerTarget, actionButton);
      }
      this.reveal(timeout);
    }
  }

  private reveal(timeout = DEFAULT_TIMEOUT) {
    const snackbarMDC = this.snackbarMDC!;
    snackbarMDC.open();
    setTimeout(() => snackbarMDC.close(), timeout);
  }

  private createActionButton(text: string, action?: string) {
    const buttonEl = document.createElement('button');
    buttonEl.classList.add('mdc-button', 'mdc-snackbar__action');
    buttonEl.setAttribute('type', 'button');
    if (action) {
      buttonEl.setAttribute('data-action', action);
    }
    buttonEl.textContent = text;
    return buttonEl;
  }
}
