// Polyfills
// TODO investigate 'legacy' and 'modern' bundles so that babel/preset-env can produce a smaller JS filesize for better browsers
import 'core-js/stable';
import 'element-closest/browser';
import 'regenerator-runtime/runtime';

// Styles
import './confinus.scss';

// https://prograils.com/posts/adding-webpacker-legacy-rails-app
// We must reference all static assets to allow us to source them in Rails too
require.context('../assets', true, /\.(svg|png|jpe?g|gif|ico|woff2?|eot|ttf)$/im);

// All the front end system is started here
import 'src/boot';
