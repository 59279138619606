import { AjaxResponseEvent } from 'src/types';

import PurchaseConfiguratorVariantController from 'src/controllers/shopping/purchase_configurator/purchase_configurator_variant_controller';
import BaseController from 'src/lib/controller/base_controller';
import i18n from 'src/lib/i18n';
import { closeDialog } from 'src/lib/overlay/dialog';
import { showSnackbar } from 'src/lib/overlay/snackbar';
import { showToast } from 'src/lib/overlay/toast';
import { markAsSafeHTML, safelySetInnerHTML } from 'src/lib/util/safe_html';
import axios from 'src/lib/axios';

export default class PurchaseConfiguratorController extends BaseController {
  public static targets = ['form'];

  // private declare readonly formTarget: HTMLFormElement;

  public onInitialize() {
    // Ensure that all fields except for the chosen variant are initialized as disabled.
    // Child controllers will connect after this one, so we listen for them here.
    // TODO we can remove this hack once Rails has support for not serializing form inputs inside disabled fieldsets
    // https://github.com/rails/rails/pull/36764
    this.onChildControllerConnect((controller: BaseController) => {
      if (controller instanceof PurchaseConfiguratorVariantController) {
        const chosenVariant = this.getRequiredDataAttr('chosenVariant');
        controller.isCurrent = controller.id === chosenVariant;
      }
    });

    return super.onInitialize();
  }

  public handleVariantChanged(evt: Event) {
    const { value: chosenVariantId } = evt.target as HTMLInputElement;
    this.chosenVariant = chosenVariantId;
  }

  public handleCancelButton(evt: Event) {
    evt.preventDefault();
    // trigger event which parent should listen for
    closeDialog();
  }

  public formSubmitSuccess(evt: AjaxResponseEvent) {
    const [data, status, xhr] = evt.detail;
    closeDialog();

    const location = xhr.responseURL
      .split('/')
      .slice(3, 7)
      .join('/');
    // Trigger update of total price
    axios
      .get<string>(`/${location}/carts/total`, { headers: { Accept: 'text/fragment' } })
      .then(resp => {
        const element = document.getElementById('top-app-bar__cart-estimated-total');
        if (element) {
          safelySetInnerHTML(element, markAsSafeHTML(resp.data));
        }
      })
      .catch(e => {
        console.warn(e);
      });

    showToast({
      content: markAsSafeHTML(xhr.response),
    });
  }

  public formSubmitError(evt: AjaxResponseEvent) {
    const [data, status, xhr] = evt.detail;
    // TODO show this in a flash container somewhere
    const response = JSON.parse(xhr.response);
    console.warn(response);
    showSnackbar({
      message: response.message || i18n.errors.generic_server_error,
      timeout: 5000,
    });
  }

  private set chosenVariant(chosenVariantId: string) {
    this.variantControllers.forEach(vc => {
      vc.isCurrent = vc.id === chosenVariantId;
    });
  }

  private get variantControllers() {
    return this.getSpecificChildControllers<PurchaseConfiguratorVariantController>(
      PurchaseConfiguratorVariantController
    );
  }
}
