import BaseController from 'src/lib/controller/base_controller';
import { closeDialog, showDialog } from 'src/lib/overlay/dialog.ts';
import { markAsSafeHTML } from 'src/lib/util/safe_html';
import { addParametersToQueryString } from 'src/lib/util/add_parameter_to_url';

function getFormElementValueByName(
  form: Element,
  namePrefix: string,
  name: string,
  checked: boolean = false
) {
  const el = form.querySelector(`input[name='${namePrefix}[${name}]']${checked ? ':checked' : ''}`);
  return el ? (el as HTMLInputElement).value : '';
}

export default class AddNoteController extends BaseController {
  public static targets = ['notes', 'triggerButton'];

  private declare readonly notesTarget: HTMLInputElement;
  private declare readonly triggerButtonTarget: HTMLInputElement;

  public handleButtonClick(evt: Event) {
    // Stop propagation: prevent any clicks leaking out
    evt.preventDefault();
    evt.stopPropagation();

    let params = {};
    if (this.data.get('lightbox') === 'true') {
      const namePrefix = this.data.get('formName');
      const form = this.findParentElementByTagName(this.notesTarget, 'form');
      if (!form || !namePrefix) return;

      params = {
        chosen_variant: getFormElementValueByName(form, namePrefix, 'chosen_variant', true),
        portion_size: getFormElementValueByName(form, namePrefix, 'portion_size'),
        quantity: getFormElementValueByName(form, namePrefix, 'quantity'),
        delivery_date: getFormElementValueByName(form, namePrefix, 'delivery_date'),
      };
    }

    // Show the dialog
    const [contentHref, placeholder] = this.getRequiredDataAttrs('contentHref', 'placeholder');
    showDialog({
      onClosing: e => {
        if (e.detail.closeReason === 'save') {
          this.notesTarget.value = (document.getElementsByName(
            'order_note[note]'
          )[0] as HTMLInputElement).value;
        }

        if (this.data.get('lightbox') === 'true') {
          // Reopen the lightbox of the product
          const [lightboxContentHref, lightboxPlaceholder] = this.getRequiredDataAttrs(
            'lightboxContentHref',
            'placeholder'
          );
          closeDialog();
          return setImmediate(() => {
            return showDialog({
              contentHref: `${lightboxContentHref}?${addParametersToQueryString('', {
                notes: this.notesTarget.value,
                ...params,
              })}`,
              placeholder: markAsSafeHTML(lightboxPlaceholder),
            });
          });
        }

        if (this.notesTarget.value) {
          // tslint:disable-next-line:no-inner-html
          this.triggerButtonTarget.getElementsByClassName('cnf-link-with-icon')[0].innerHTML =
            '<i class="material-icons">assignment</i>Edit note';
        } else {
          // tslint:disable-next-line:no-inner-html
          this.triggerButtonTarget.getElementsByClassName('cnf-link-with-icon')[0].innerHTML =
            '<i class="material-icons">content_paste</i>Add a note to vendor';
        }
      },
      contentHref: contentHref + '?note=' + encodeURIComponent(this.notesTarget.value),
      placeholder: markAsSafeHTML(placeholder),
    });
  }
}
