import { MDCDataTable } from '@material/data-table/index';

import BaseController from 'src/lib/controller/base_controller';

export default class TableController extends BaseController {
  public onInitialize() {
    this.onConnect(() => {
      const dataTableMDC = new MDCDataTable(this.element);
      return () => {
        dataTableMDC.destroy();
      };
    });

    return super.onInitialize();
  }
}
