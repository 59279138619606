import BaseController from 'src/lib/controller/base_controller';

export default class SelectToggleController extends BaseController {
  public static targets = ['privateSelect', 'sharedSelect'];

  private declare readonly privateSelectTarget: HTMLSelectElement;
  private declare readonly sharedSelectTarget: HTMLSelectElement;

  public clearSharedSelect() {
    this.sharedSelectTarget.selectedIndex = 0;
  }

  public clearPrivateSelect() {
    this.privateSelectTarget.selectedIndex = 0;
  }
}
