import PurchaseConfiguratorVariantController from 'src/controllers/shopping/purchase_configurator/purchase_configurator_variant_controller';
import { calculateExtraInfoAmountForDisplay } from 'src/helpers/shopping/purchase_configurator_helper';
import i18n from 'src/lib/i18n';

interface IDataAttributes {
  multiplier: string | number;
  sizeExcludedFromCalculation?: boolean;
  sizeCalculationPerformDivision?: boolean;
}

interface IUpdateValueOptions {
  quantity: string;
  quantityType: string;
  size?: string;
  fallbackDisplay: string;
}

abstract class BasePurchaseConfiguratorExtraInfoController extends PurchaseConfiguratorVariantController {
  public readonly valueTarget!: HTMLSpanElement;
}

export default class PurchaseConfiguratorExtraInfoController extends (PurchaseConfiguratorVariantController as typeof BasePurchaseConfiguratorExtraInfoController) {
  public static targets = ['value'];

  public updateValue({ quantity, quantityType, size, fallbackDisplay }: IUpdateValueOptions) {
    let valueDisplay = null;

    try {
      const {
        multiplier,
        sizeExcludedFromCalculation,
        sizeCalculationPerformDivision,
      } = this.getRequiredDataAttrAsJSON<IDataAttributes>('data');
      valueDisplay = calculateExtraInfoAmountForDisplay({
        locale: i18n.locale,
        multiplier,
        quantity,
        quantityType,
        size,
        sizeCalculationPerformDivision,
        sizeExcludedFromCalculation,
      });
    } catch (e) {
      // Ru-roh, something's gone wrong when parsing the attributes
      // TODO handle this error: show the user somehow?
      console.warn(e);
    }

    // Set the value, or the fallback
    this.value = valueDisplay !== null ? valueDisplay : fallbackDisplay;
  }

  private set value(val: string) {
    this.valueTarget.textContent = val;
  }
}
