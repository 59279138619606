import { MDCDialog } from '@material/dialog/index';

import axios from 'src/lib/axios';
import BaseController from 'src/lib/controller/base_controller';
import i18n from 'src/lib/i18n';
import { createCustomEvent } from 'src/lib/util/create_custom_event';
import { replaceContentsWithChildren } from 'src/lib/util/replace_with_dom_nodes';
import { markAsSafeHTML, safelySetInnerHTML } from 'src/lib/util/safe_html';

import {
  DialogShowEvent,
  IDialogAction,
  IDialogLifecycleEventDetail,
  ISafeHTMLContent,
  MDCDialogClosedEvent,
} from 'src/types';

interface IContentsNode {
  title?: Node;
  content?: Node;
  actions?: Node;
}

interface IContentOptions {
  rawContent?: boolean;
}

export default class DialogController extends BaseController {
  public static targets = ['surface'];

  private declare readonly surfaceTarget: HTMLDivElement;

  private dialogMDC: MDCDialog | null = null;

  public onInitialize() {
    this.onConnect(() => {
      const dialogMDC = new MDCDialog(this.element);
      dialogMDC.listen('MDCDialog:closing', this.dispatchClosingEvent);
      dialogMDC.listen('MDCDialog:closed', this.dispatchClosedEvent);
      dialogMDC.listen('MDCDialog:opening', this.dispatchOpeningEvent);

      if (this.data.get('showInitial')) {
        this.reveal();
      }

      this.dialogMDC = dialogMDC;

      return () => {
        dialogMDC.destroy();
        this.dialogMDC = null;
      };
    });

    return super.onInitialize();
  }

  public handleCloseEvent(evt: MDCDialogClosedEvent | IDialogLifecycleEventDetail) {
    const action = (evt as any).detail?.closeReason || (evt as any).detail?.action;
    this.close(action);
  }

  // Reveal the dialog by calling:
  // window.dispatchEvent(new CustomEvent('cnf-dialog:show', { detail: { message } }));
  public async handleShowEvent(evt: Event) {
    const {
      detail: {
        title,
        content,
        contentHref,
        dialogVariant = 'regular',
        rawContent,
        placeholder,
        actions,
        defaultAction,
      },
    } = evt as DialogShowEvent;

    const nodes: IContentsNode = {};
    if (title) {
      nodes.title = this.createTitle(title);
    }

    if (actions) {
      nodes.actions = this.createActions(actions, defaultAction);
    }

    if (content) {
      nodes.content = this.createContent(content, { rawContent });
    } else if (contentHref && !placeholder) {
      nodes.content = this.createContent(await this.getContent(contentHref), { rawContent });
      this.dispatchLifecycleEvent('loaded');
    } else if (contentHref && placeholder) {
      nodes.content = this.createContent(placeholder);
      this.getContent(contentHref)
        .then(c => {
          nodes.content = this.createContent(c, { rawContent });
          this.dispatchLifecycleEvent('loaded');
        })
        .catch(err => {
          console.warn(err);
          const errorMessage = i18n.errors.generic_server_error;
          nodes.content = this.createContent(markAsSafeHTML(errorMessage));
        })
        .finally(() => {
          this.replaceContents(this.surfaceTarget, nodes);
          this.dispatchLifecycleEvent('loaded');
        });
    }

    this.replaceContents(this.surfaceTarget, nodes);
    this.applyVariantClassesToTarget(dialogVariant, this.surfaceTarget);
    this.reveal();
  }

  public get isOpen() {
    return this.dialogMDC!.isOpen;
  }

  private reveal() {
    this.dialogMDC!.open();
  }

  private close(action?: string) {
    this.dialogMDC!.close(action);
  }

  private applyVariantClassesToTarget(variant: string, target: HTMLDivElement) {
    if (variant && target) {
      target.classList.remove('mdc-dialog__surface--regular', 'mdc-dialog__surface--narrow');
      target.classList.add(`mdc-dialog__surface--${variant}`);
    }
  }

  private replaceContents(target: HTMLDivElement, sections: IContentsNode) {
    const filteredSections = [sections.title, sections.content, sections.actions].filter(
      v => !!v
    ) as Node[];
    replaceContentsWithChildren(target, filteredSections);
  }

  private async getContent(url: string): Promise<ISafeHTMLContent> {
    return axios
      .get<string>(url, {
        headers: {
          'Content-Type': 'text/html',
        },
      })
      .then(response => markAsSafeHTML(response.data));
  }

  private createTitle(title: string) {
    const heading = document.createElement('h2');
    heading.classList.add('mdc-dialog__title');
    heading.id = `${this.element.id}-title`;
    heading.textContent = title.trim();
    return heading;
  }

  private createContent(content: ISafeHTMLContent, options: IContentOptions = {}) {
    const contentContainer = document.createElement('div');
    contentContainer.id = `${this.element.id}-content`;
    if (!options.rawContent) {
      contentContainer.classList.add('mdc-dialog__content');
    }

    // We likely want to allow arbitrary HTML here so that the dialog content can be formatted appropriately, and allow things like other MDC components to be rendered inside.
    // The problem with allowing arbitrary HTML to be rendered is that it opens the possibility of a XSS attack.
    // To try and mitigate this issue, we ask that the developer explicitly wraps the content inside an object with a `__safe` property.
    // Of course, this can't guarantee that a developer is passing safe content, but it at least gets them thinking about the dangers of XSS when pushing content to be displayed.
    // https://www.owasp.org/index.php/Cross-site_Scripting_(XSS)
    safelySetInnerHTML(contentContainer, content);

    return contentContainer;
  }

  private createActions(actions: IDialogAction[], defaultAction?: string) {
    const container = document.createElement('footer');
    container.classList.add('mdc-dialog__actions');

    actions.forEach(({ title: buttonTitle, action: buttonAction }) => {
      const isDefaultAction = actions.length === 1 || buttonAction === defaultAction;
      const button = this.createActionButton(buttonTitle, buttonAction, isDefaultAction);
      container.appendChild(button);
    });

    return container;
  }

  private createActionButton(title: string, action: string, isDefault: boolean) {
    const buttonEl = document.createElement('button');
    const buttonClassNames = ['mdc-button', 'mdc-dialog__button'];
    if (isDefault) {
      buttonClassNames.push('mdc-dialog__button--default');
    }
    buttonEl.classList.add(...buttonClassNames);
    buttonEl.setAttribute('type', 'button');
    buttonEl.setAttribute('data-mdc-dialog-action', action);

    const buttonLabelEl = document.createElement('span');
    buttonLabelEl.classList.add('mdc-button__label');
    buttonEl.textContent = title;

    buttonEl.appendChild(buttonLabelEl);
    return buttonEl;
  }

  private dispatchClosingEvent = (evt: Event) => {
    const {
      detail: { action },
    } = evt as MDCDialogClosedEvent;
    this.dispatchLifecycleEvent('closing', action);
  };

  private dispatchClosedEvent = (evt: Event) => {
    const {
      detail: { action },
    } = evt as MDCDialogClosedEvent;
    this.dispatchLifecycleEvent('closed', action);
  };

  private dispatchOpeningEvent = (evt: Event) => {
    const {
      detail: { action },
    } = evt as MDCDialogClosedEvent;
    this.dispatchLifecycleEvent('opening', action);
  };

  private dispatchLifecycleEvent(type: string, closeReason?: string) {
    const detail: IDialogLifecycleEventDetail = {
      closeReason,
    };
    const evt = createCustomEvent(`cnf-dialog:${type}`, detail);
    window.dispatchEvent(evt);
  }
}
