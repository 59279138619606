import { DialogLifecycleEvent } from 'src/types';

import ProductLightboxController from 'src/controllers/shopping/product_lightbox_controller';
import axios from 'src/lib/axios';
import BaseController from 'src/lib/controller/base_controller';
import { closeDialog, showDialog } from 'src/lib/overlay/dialog';
import { markAsSafeHTML } from 'src/lib/util/safe_html';

export default class CartItemController extends BaseController {
  private lightboxController: ProductLightboxController | null = null;

  public onInitialize() {
    this.onChildControllerConnect((controller: BaseController) => {
      if (controller instanceof ProductLightboxController) {
        this.lightboxController = controller;
      }
    });

    this.onDisconnect(() => {
      this.lightboxController = null;
    });

    return super.onInitialize();
  }

  public edit() {
    if (this.lightboxController !== null) {
      this.lightboxController.showPurchaseConfigurator();
    }
  }

  public removalWithoutConfirm() {
    this.deleteCartItem();
  }

  public showRemovalConfirm() {
    showDialog({
      actions: [
        {
          action: 'cancel',
          title: 'No',
        },
        {
          action: 'ok',
          title: 'Yes',
        },
      ],
      content: markAsSafeHTML(`Are you sure that you want to delete this item?`),
      defaultAction: 'ok',
      onClosing: this.handleDialogClosing,
    });
  }

  private handleDialogClosing = ({ detail: { closeReason } }: DialogLifecycleEvent) => {
    if (closeReason === 'ok') {
      this.deleteCartItem();
    } else {
      closeDialog();
    }
  };

  private deleteCartItem() {
    const url = this.data.get('url');

    if (url === null) {
      return;
    }

    axios
      .delete(url)
      .then(() => {
        window.location.reload();
      })
      .catch((e: Error) => {
        console.log(e.message);
      });
  }
}
