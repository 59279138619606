import Big from 'big.js';

interface ITotalPriceOpts {
  extraChargeMultiplier: string | number;
  priceMultiplier: string | number;
  quantity: string;
  quantityType: string;
  size?: string;
  sizeExcludedFromCalculation?: boolean;
}

interface ITotalPriceForDisplayOpts extends ITotalPriceOpts {
  currency: string;
  locale: string;
}

interface IExtraInfoAmountForDisplayOpts {
  locale: string;
  multiplier: string | number;
  quantity: string;
  quantityType: string;
  size?: string;
  sizeCalculationPerformDivision?: boolean;
  sizeExcludedFromCalculation?: boolean;
}

const ZERO = Number(0);
const BIG_ZERO = new Big(0);

export function calculateTotalPriceForDisplay(opts: ITotalPriceForDisplayOpts): string | null {
  const { currency, locale, ...priceFactors } = opts;

  const totalPriceBig = calculateTotalPrice(priceFactors);
  const totalPriceNumber = totalPriceBig !== null ? Number.parseFloat(totalPriceBig.toFixed(2)) : 0;

  return totalPriceNumber.toLocaleString(locale, {
    currency,
    style: 'currency',
  });
}

export function calculateExtraInfoAmountForDisplay({
  locale,
  multiplier,
  quantity,
  quantityType,
  size,
  sizeCalculationPerformDivision,
  sizeExcludedFromCalculation,
}: IExtraInfoAmountForDisplayOpts) {
  const quantityBig = parseQuantity(quantity, quantityType);
  if (isNullOrBigZero(quantityBig)) {
    return ZERO.toLocaleString(locale);
  }

  const sizeBig = parseSize(size, sizeExcludedFromCalculation);
  if (isNullOrBigZero(sizeBig)) {
    return ZERO.toLocaleString(locale);
  }

  const multiplierBig = parseQuantityPriceMultiplier(multiplier);
  if (isNullOrBigZero(multiplierBig)) {
    return ZERO.toLocaleString(locale);
  }

  // Use big.js to perform arithmetic, to prevent floating point rounding errors
  let extraInfoAmountBig = multiplierBig.times(quantityBig!);
  extraInfoAmountBig = sizeCalculationPerformDivision
    ? extraInfoAmountBig.div(sizeBig)
    : extraInfoAmountBig.times(sizeBig);

  // Format for display
  const extraInfoAmountNumber = Number.parseFloat(extraInfoAmountBig.toFixed(2));
  return extraInfoAmountNumber.toLocaleString(locale);
}

function calculateTotalPrice({
  extraChargeMultiplier,
  priceMultiplier,
  quantity,
  quantityType,
  size,
  sizeExcludedFromCalculation,
}: ITotalPriceOpts): Big | null {
  const quantityBig = parseQuantity(quantity, quantityType);
  if (quantityBig === null) {
    return null;
  }

  const sizeBig = parseSize(size, sizeExcludedFromCalculation);
  const priceMultiplierBig = parseQuantityPriceMultiplier(priceMultiplier);
  const extraChargeMultiplierBig = parseQuantityPriceMultiplier(extraChargeMultiplier);

  // Use big.js to perform arithmetic, to prevent floating point rounding errors
  const totalMultiplierBig = priceMultiplierBig.add(extraChargeMultiplierBig);
  return totalMultiplierBig
    .times(quantityBig)
    .times(sizeBig)
    .div(100);
}

function parseQuantity(quantityRaw: string, quantityType: string): Big | null {
  switch (quantityType) {
    case 'float': {
      return newPositiveBigOrNull(quantityRaw);
    }

    case 'integer': {
      if (quantityRaw.includes('.')) {
        // Can't parse a decimal number as an integer.
        return null;
      }
      return newPositiveBigOrNull(quantityRaw);
    }

    default:
      // quantityType not recognised
      throw new Error(
        `quantityType '${quantityType || '""'}' is invalid, should be 'float' or 'integer'`
      );
  }
}

function parseSize(sizeRaw?: string, sizeExcludedFromCalculation?: boolean): Big {
  // Intentionally double equals to check for undefined and null
  if (sizeRaw == null || sizeExcludedFromCalculation) {
    return new Big(1);
  }
  return newPositiveBigOrNull(sizeRaw) || new Big(1);
}

function parseQuantityPriceMultiplier(multiplierRaw: string | number): Big {
  const multiplierBig = newPositiveBigOrNull(multiplierRaw);
  if (multiplierBig === null) {
    // This is an error that the user cannot recover from.
    throw new Error(`multiplier was not a positive number: ${multiplierRaw || '""'}`);
  }

  return multiplierBig;
}

function newPositiveBigOrNull(input: string | number): Big | null {
  if (typeof input === 'string' && input.length === 0) {
    return null;
  }
  try {
    const big = new Big(input);
    // TODO: max value should probably come from configuration
    return big.gte(0) && big.lte(1000000) ? big : null;
  } catch (error) {
    return null;
  }
}

function isNullOrBigZero(input: Big | null) {
  return input === null || BIG_ZERO.eq(input);
}
