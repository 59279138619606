import Rails from '@rails/ujs';
import { showDialog } from './overlay/dialog';
import { markAsSafeHTML } from './util/safe_html';

import { DialogLifecycleEvent } from 'src/types';

const alwaysTrue = () => true;

const customConfirm = (message: string, element: HTMLElement) => {
  // Listen for the dialog closing event.
  // If the closeReason corresponds to the 'confirm' action, then allow the confirm through.
  function handleDialogClosing({ detail: { closeReason } }: DialogLifecycleEvent) {
    if (closeReason === 'confirm') {
      // Song and dance to re-submit the original action.
      Rails.confirm = alwaysTrue;
      element.click();
      Rails.confirm = customConfirm;
    }
  }

  // Reveal the custom dialog.
  // TODO integrate with i18n module
  const confirmLabel = element.getAttribute('data-confirm-yes') || 'OK';
  const cancelLabel = element.getAttribute('data-confirm-no') || 'Cancel';
  showDialog({
    actions: [
      {
        action: 'cancel',
        title: cancelLabel,
      },
      {
        action: 'confirm',
        title: confirmLabel,
      },
    ],
    content: markAsSafeHTML(message),
    defaultAction: 'cancel',
    onClosing: handleDialogClosing,
  });

  // Interaction with dialog happens asynchronously,
  // so we need to initially return false to prevent submission.
  return false;
};

Rails.confirm = customConfirm;

export default Rails;
