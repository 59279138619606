import { MDCFormField } from '@material/form-field/index';
import { MDCSwitch } from '@material/switch/index';

import FormFieldController from 'src/lib/controller/form_field_controller';
import { showDialog } from 'src/lib/overlay/dialog.ts';
import { createCustomEvent } from 'src/lib/util/create_custom_event';
import { markAsSafeHTML } from 'src/lib/util/safe_html';
import { DialogLifecycleEvent } from 'src/types';

export default class SwitchController extends FormFieldController {
  public static targets = FormFieldController.targets;

  private mdcSwitch?: MDCSwitch;

  public onInitialize() {
    this.onConnect(() => {
      const mdcSwitch = new MDCSwitch(this.containerTarget);
      const formField = new MDCFormField(this.element);

      if (this.data.has('ripple')) {
        formField.input = mdcSwitch;
      }

      this.mdcSwitch = mdcSwitch;

      return () => {
        formField.destroy();
        mdcSwitch.destroy();
        this.mdcSwitch = undefined;
      };
    });

    return super.onInitialize();
  }

  public handleChange() {
    if (this.data.has('submitOnChange')) {
      if (!this.data.has('confirmOnSubmit')) {
        // We can submit this right away.
        this.submitForm();
        return;
      }

      // Check if the form is valid - if it is, pop open a confirmation dialog.
      const validateEvt = createCustomEvent('cnf:form-validate', {
        onValidated: this.handleFormValidatedBeforeConfirm,
      });
      this.element.dispatchEvent(validateEvt);
    }
  }

  protected get invalidClassName() {
    return 'mdc-switch--invalid';
  }

  private handleFormValidatedBeforeConfirm = (isFormValid: boolean) => {
    if (isFormValid) {
      // Show the confirmation dialog, using the text sent in via data attributes.
      const confirmMessage = this.data.get('confirmOnSubmit');

      // TODO i18n
      const confirmAction = this.data.get('confirmOnSubmitYes') || 'Confirm';
      const cancelAction = this.data.get('confirmOnSubmitNo') || 'Cancel';

      showDialog({
        actions: [
          {
            action: 'cancel',
            title: cancelAction,
          },
          {
            action: 'confirm',
            title: confirmAction,
          },
        ],
        content: markAsSafeHTML(confirmMessage!),
        defaultAction: 'cancel',
        onClosing: this.handleDialogClosing,
      });
    } else {
      this.toggleSwitch();
    }
  };

  private handleDialogClosing = ({ detail: { closeReason } }: DialogLifecycleEvent) => {
    if (closeReason === 'confirm') {
      // Submit the form.
      this.submitForm();
    } else {
      // Go back to previous state
      this.toggleSwitch();
    }
  };

  private submitForm = () => {
    // Ask the parent controller to submit the form.
    const evt = createCustomEvent('cnf:form-submit', {
      onSubmissionPrevented: this.toggleSwitch,
    });
    this.element.dispatchEvent(evt);
  };

  private toggleSwitch = () => {
    this.mdcSwitch!.checked = !this.mdcSwitch!.checked;
  };
}
