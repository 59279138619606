import BaseController from 'src/lib/controller/base_controller';

const ACTIVE_CLASS = 'shopping__delivery-cut-off-pill--active';
const MINUTES_BEFORE_RUN = 30;
const SECONDS_BEFORE_RUN = MINUTES_BEFORE_RUN * 60;

export default class DeliveryCutOffPillController extends BaseController {
  public onInitialize() {
    this.onConnect(() => {
      const timeToCutOff = this.timeToCutOff;
      if (timeToCutOff !== null && timeToCutOff > 0) {
        const timeToActivateElement = timeToCutOff - SECONDS_BEFORE_RUN;
        const activateTimerId = this.activateElement(this.calculateTimeout(timeToActivateElement));
        const inactivateTimerId = this.deactivateElement(this.calculateTimeout(timeToCutOff));
        return () => {
          clearTimeout(activateTimerId);
          clearTimeout(inactivateTimerId);
        };
      }
    });

    return super.onInitialize();
  }

  public activateElement = (timeout: number) => {
    let timerId: any;
    if (timeout > 0) {
      timerId = setTimeout(this.addActiveClass, timeout);
    } else {
      this.addActiveClass();
    }
    return timerId || null;
  };

  public deactivateElement = (timeout: number) => {
    let timerId: any;
    if (timeout > 0) {
      timerId = setTimeout(this.removeActiveClass, timeout);
    }
    return timerId;
  };

  public calculateTimeout(timeoutInSeconds: number) {
    return timeoutInSeconds * 1000;
  }

  public addActiveClass = () => {
    this.element.classList.add(ACTIVE_CLASS);
  };

  public removeActiveClass = () => {
    this.element.classList.remove(ACTIVE_CLASS);
  };

  private get timeToCutOff(): number | null {
    const timeToCutOff = this.data.get('timeToCutOff') as any;
    if (timeToCutOff === null) {
      return timeToCutOff;
    }
    return timeToCutOff as number;
  }
}
