import BaseController from 'src/lib/controller/base_controller';
import { closeDialog, showDialog } from 'src/lib/overlay/dialog.ts';
import { IDialogLifecycleEventDetail } from 'src/types';

export default class DialogManagerController extends BaseController {
  public handleButtonClick() {
    const action = this.getRequiredDataAttr('action');
    switch (action) {
      case 'show':
        this.show();
        break;
      case 'save':
        this.close({ closeReason: 'save' });
        break;
      case 'close':
        this.close();
        break;
    }
  }

  private show() {
    const [href, dialogVariant] = this.getRequiredDataAttrs('href', 'dialogVariant');
    showDialog({
      contentHref: href,
      dialogVariant,
    });
  }

  private close(opts?: IDialogLifecycleEventDetail) {
    closeDialog(opts);
  }
}
