import BaseController from 'src/lib/controller/base_controller';

const DISABLED_CLASS = 'cnf-data-table__row--disabled';

export default class TableRowController extends BaseController {
  public static targets = ['expandButton'];

  // private declare readonly expandButtonTarget: HTMLSpanElement;
  private declare readonly hasExpandButtonTarget: boolean;

  public get id() {
    return this.getRequiredDataAttr('id');
  }

  public get disabled() {
    return this.element.classList.contains(DISABLED_CLASS);
  }

  public set disabled(disabled: boolean) {
    if (disabled) {
      this.element.classList.add(DISABLED_CLASS);
    } else {
      this.element.classList.remove(DISABLED_CLASS);
    }
  }

  public get index() {
    return this.data.get('index');
  }

  public handleRowClick(e: MouseEvent) {
    const href = this.data.get('href');
    if (href) {
      window.location.href = href;
    } else if (this.hasExpandButtonTarget) {
      this.handleExpandClick(e);
    }
  }

  public handleExpandClick(e: MouseEvent) {
    // Stop this propagating up to the table row
    e.stopPropagation();

    // TODO expand/contract the panel
    // We need to push this up to the table controller so it knows which panel to expand + contract
    // This is complicated by the fact that tables have a required structure tbody -> tr
    this.toggleExpandButton();
    this.dispatchExpandEvent();
  }

  public toggleExpandButton() {
    // this.expandButtonTarget.classList.toggle('mdl-data-table__row__expand-button--rotated');
  }

  private dispatchExpandEvent() {
    // const evt = createCustomEvent('cnf-table-row:expand', {
    //   identifier: this.identifier,
    //   index: this.data.get('index'),
    // });
    // this.element.dispatchEvent(evt);
  }
}
