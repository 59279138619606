/**
 * Creates a custom DOM event.
 * TODO use a polyfill instead of this.
 *
 * @param href the URL of the script
 * @param defer if the script should defer execution till page loaded
 * @param async if the script load should be async
 */

export interface IAddScriptOptions {
  defer?: boolean;
  async?: boolean;
  id?: string;
}

export function addScriptTag(href: string, options: IAddScriptOptions = {}) {
  const scriptTag = document.createElement('script');
  scriptTag.type = 'text/javascript';
  if (options.async) {
    scriptTag.async = true;
  }
  if (options.defer) {
    scriptTag.async = true;
  }
  if (options.id) {
    scriptTag.id = options.id;
  }
  scriptTag.src = href;
  document.getElementsByTagName('head')[0].appendChild(scriptTag);
}
