import { Instance } from 'flatpickr/dist/types/instance';
import { createCustomEvent } from '../../lib/util/create_custom_event';

export interface IDataAttributes {
  defaultDate: string;
  maxDate: string;
  minDate: string;

  enableDates: string[];
  enableDaysOfWeek: number[];

  disableDates: string[];
  disableDaysOfWeek: number[];

  altInput: boolean;
  altFormat: string;
}

export function buildFlatpickrOptions({
  defaultDate,
  maxDate,
  minDate,
  enableDates,
  enableDaysOfWeek,
  disableDates,
  disableDaysOfWeek,
  altInput,
  altFormat,
}: IDataAttributes) {
  const disableDatesArr = dates(disableDates);
  const enableDatesArr = dates(enableDates);
  const options = {
    altFormat,
    altInput,
    defaultDate,
    disable: [
      (date: Date): boolean => {
        if (disableDatesArr.length === 0 && disableDaysOfWeek.length === 0) {
          return false;
        }

        return (
          disableDaysOfWeek.includes(date.getDay()) ||
          disableDatesArr.some((disabledDate: Date) => sameDates(disabledDate, date))
        );
      },
    ],
    enable: [],
    maxDate,
    minDate,
    onChange: (selectedDates: Date[], dateStr: string, instance: Instance) => {
      const dateSelectEvent = createCustomEvent('cnf:delivery-date-selected', {
        selectedDate: selectedDates[0],
      });

      instance.input.dispatchEvent(dateSelectEvent);
    },
    onReady: (selectedDates: Date[], dateStr: string, instance: Instance) => {
      if (defaultDate && instance.altInput) {
        instance.altInput.value = instance.formatDate(new Date(defaultDate), altFormat);
      }
    },
  };

  // Will override 'disable' option if specified instantly
  if (enableDatesArr.length > 0 || enableDaysOfWeek.length > 0) {
    options.enable = [
      (date: Date): boolean => {
        return (
          enableDaysOfWeek.includes(date.getDay()) ||
          enableDatesArr.some((enabledDate: Date) => sameDates(enabledDate, date))
        );
      },
    ];
  }

  return options;
}

function dates(dateStrings: string[]): Date[] {
  return dateStrings.map((strDate: string) => new Date(strDate));
}

function sameDates(time1: Date, time2: Date): boolean {
  const date1 = new Date(time1).setHours(0, 0, 0, 0);
  const date2 = new Date(time2).setHours(0, 0, 0, 0);

  return date1 === date2;
}
