/**
 * Creates a custom DOM event.
 * TODO use a polyfill instead of this.
 *
 * @param name name of the event
 * @param data data to be sent with the custom event
 */
export function createCustomEvent(name: string, data?: object) {
  if (typeof CustomEvent === 'function') {
    // Modern browsers
    return new CustomEvent(name, {
      bubbles: true,
      cancelable: false,
      detail: data,
    });
  }
  // IE
  const evt = document.createEvent('CustomEvent');
  evt.initCustomEvent(name, true, false, data);
  return evt;
}
