import debounce from 'lodash.debounce';
import shave from 'shave';

import BaseController from 'src/lib/controller/base_controller';

const DEBOUNCE_TIMEOUT_MS = 200;

export default class LineClampController extends BaseController {
  private originalText: string | null = null;
  private shouldActOnResize = false;

  public onInitialize() {
    this.onConnect(() => {
      this.originalText = this.element.textContent;
      this.clamp();

      const handleResizeDebounced = debounce(this.handleResize.bind(this), DEBOUNCE_TIMEOUT_MS);
      window.addEventListener('resize', handleResizeDebounced);
      this.shouldActOnResize = true;

      return () => {
        this.resetText();
        this.shouldActOnResize = false;
        window.removeEventListener('resize', handleResizeDebounced);
      };
    });

    return super.onInitialize();
  }

  public clamp() {
    // Use the height of the element set by the stylesheet
    const targetHeight = this.element.getBoundingClientRect().height;
    shave(this.element, targetHeight);
  }

  private handleResize() {
    if (this.shouldActOnResize) {
      // Reset the text first - in case the window is now wider than before
      this.resetText();
      this.clamp();
    }
  }

  private resetText() {
    this.element.textContent = this.originalText;
  }
}
