import { createCustomEvent } from 'src/lib/util/create_custom_event';
import { DialogLifecycleEvent, IDialogLifecycleEventDetail, IDialogShowOptions } from 'src/types';

export function showDialog(opts: IDialogShowOptions) {
  const { onClosing, ...rest } = opts;
  function closingHandler(e: Event) {
    window.removeEventListener('cnf-dialog:closing', closingHandler);
    if (onClosing) {
      onClosing(e as DialogLifecycleEvent);
    }
  }

  window.addEventListener('cnf-dialog:closing', closingHandler);

  const evt = createCustomEvent('cnf-dialog:open', rest);
  window.dispatchEvent(evt);
}

export function closeDialog(opts?: IDialogLifecycleEventDetail) {
  const evt = createCustomEvent('cnf-dialog:close', opts);
  window.dispatchEvent(evt);
}
