import BaseController from 'src/lib/controller/base_controller';

export default abstract class PurchaseConfiguratorVariantController extends BaseController {
  public get id() {
    return this.data.get('variantId');
  }

  public set isCurrent(isCurrent: boolean) {
    const classToToggle = 'shopping__purchase-configurator__variant-details--current';
    // IE11 don't support second parameter in classList.toggle(classNmae, condition)
    isCurrent
      ? this.element.classList.add(classToToggle)
      : this.element.classList.remove(classToToggle);
  }
}
