import { INotificationData } from 'src/types';
import { createCustomEvent } from '../../lib/util/create_custom_event';
import NotificationsController from './notifications_controller';

export default class NotificationsEmitterController extends NotificationsController {
  public handleNewMessage(data: INotificationData) {
    const { message } = data;
    if (
      message.sender_user_uid !== this.data.get('current-user-uid') &&
      message.type === 'Message'
    ) {
      this.incrementCounter();
    }

    const event = createCustomEvent('notification', data);
    window.dispatchEvent(event);
  }
}
