import { MDCDialog } from '@material/dialog/index';

import axios from 'src/lib/axios';
import BaseController from 'src/lib/controller/base_controller';
import { markAsSafeHTML, safelySetInnerHTML } from 'src/lib/util/safe_html';

export default class CartMenuItemController extends BaseController {
  public static targets = ['anchor', 'dialog', 'dialogContent'];

  private declare readonly anchorTarget: HTMLAnchorElement;
  private declare readonly dialogTarget: HTMLDivElement;
  private declare readonly dialogContentTarget: HTMLDivElement;

  private dialogMDC: MDCDialog | null = null;

  public onInitialize() {
    this.onConnect(() => {
      const dialogMDC = new MDCDialog(this.dialogTarget);
      this.dialogMDC = dialogMDC;

      return () => {
        dialogMDC.destroy();
        this.dialogMDC = null;
      };
    });
    return super.onInitialize();
  }

  public handleClick(e: MouseEvent) {
    if (this.dialogMDC!.isOpen) {
      e.preventDefault();
      return void this.hideDialog();
    }

    const { href } = this.anchorTarget;
    if (href) {
      e.preventDefault();
      this.showDialog(href);
    }
  }

  private showDialog(fallbackHref: string) {
    const [contentHref, placeholder] = this.getRequiredDataAttrs('contentHref', 'placeholder');

    // Set the placeholder and open the dialog box
    safelySetInnerHTML(this.dialogContentTarget, markAsSafeHTML(placeholder));
    this.dialogMDC!.open();

    // Fetch the real content.
    // Once it has landed, render it out
    axios
      .get<string>(contentHref, { headers: { Accept: 'text/fragment' } })
      .then(resp => {
        // May have been closed in the meantime
        if (this.dialogMDC!.isOpen) {
          safelySetInnerHTML(this.dialogContentTarget, markAsSafeHTML(resp.data));
        }
      })
      .catch(e => {
        // May have been closed in the meantime
        if (this.dialogMDC!.isOpen) {
          this.hideDialog();
        }
        // TODO maybe want to show an error here?
        console.warn(e);
        window.location.href = fallbackHref;
      });
  }

  private hideDialog() {
    this.dialogMDC!.close();
  }
}
