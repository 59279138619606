import BaseController from 'src/lib/controller/base_controller';
import { hideElement, setElementHidden, unhideElement } from 'src/lib/util/set_element_hidden';
import { setElementRequired } from 'src/lib/util/set_element_required';

export default class RegistrationStep1Controller extends BaseController {
  public static targets = [
    'consumerChoices',
    'consumerPropertyTypeOtherCheckbox',
    'consumerPropertyTypeOtherTextFieldContainer',
    'consumerPropertyTypeOtherTextField',
  ];

  // private declare readonly consumerChoicesTarget: HTMLDivElement;
  private declare readonly consumerPropertyTypeOtherCheckboxTarget: HTMLInputElement;
  private declare readonly consumerPropertyTypeOtherTextFieldContainerTarget: HTMLDivElement;
  private declare readonly consumerPropertyTypeOtherTextFieldTarget: HTMLInputElement;

  public onInitialize() {
    this.onConnect(() => {
      this.toggleConsumerPropertyTypeOtherTextFieldControl(
        this.consumerPropertyTypeOtherCheckboxTarget.checked
      );
    });

    return super.onInitialize();
  }

  public handleConsumerPropertyTypeOtherCheckboxToggle(evt: Event) {
    const { checked } = evt.target as HTMLInputElement;
    this.toggleConsumerPropertyTypeOtherTextFieldControl(checked);
  }

  private toggleConsumerPropertyTypeOtherTextFieldControl(reveal: boolean) {
    setElementHidden(this.consumerPropertyTypeOtherTextFieldContainerTarget, !reveal);
    setElementRequired(this.consumerPropertyTypeOtherTextFieldTarget, reveal);
  }
}
