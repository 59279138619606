export function hideElement(element: Element) {
  setElementHidden(element, true);
}

export function unhideElement(element: Element) {
  setElementHidden(element, false);
}

export function setElementHidden(element: Element, hidden: boolean) {
  element.classList.toggle('cnf-hidden', hidden);
}
