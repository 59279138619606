const DEFAULT_TIMEOUT = 300;

export default function trackHover(
  eventType: string,
  target: HTMLBaseElement | HTMLElement,
  callback: () => void,
  timeout = DEFAULT_TIMEOUT
) {
  target.addEventListener(eventType, () => {
    global.clearTimeout(timer);
  });

  const timer = setTimeout(callback, timeout);
}
