import BaseController from 'src/lib/controller/base_controller';

export default class EntityFormController extends BaseController {
  public static targets = [
    'checkboxOther',
    'blockCustomType',
    'inputCustomType',
    'inputEmail',
    'blockEmailConfirmation',
    'inputEmailConfirmation',
  ];

  private declare readonly checkboxOtherTarget: HTMLInputElement;
  private declare readonly inputCustomTypeTarget: HTMLInputElement;
  private declare readonly blockCustomTypeTarget: HTMLDivElement;
  private declare readonly inputEmailTarget: HTMLInputElement;
  private declare readonly blockEmailConfirmationTarget: HTMLDivElement;
  private declare readonly inputEmailConfirmationTarget: HTMLInputElement;

  public onInitialize() {
    this.handleOtherPropertyTypeChanges(false);
    this.handleEmailChanges();

    return super.onInitialize();
  }

  public handleEmailChanges() {
    if (this.inputEmailTarget.value === this.data.get('originalEmail')) {
      this.blockEmailConfirmationTarget.style.visibility = 'hidden';
      this.inputEmailConfirmationTarget.disabled = true;
    } else {
      this.inputEmailConfirmationTarget.disabled = false;
      this.inputEmailConfirmationTarget.parentElement!.classList.remove('mdc-text-field--disabled');
      this.blockEmailConfirmationTarget.style.visibility = 'visible';
    }
  }

  public handleOtherPropertyTypeChanges(focus = true) {
    if (this.checkboxOtherTarget.checked) {
      this.blockCustomTypeTarget.classList.remove('cnf-hidden');
      if (focus) {
        this.inputCustomTypeTarget.focus();
      }
    } else {
      this.blockCustomTypeTarget.classList.add('cnf-hidden');
    }
  }
}
