import FormFieldController from 'src/lib/controller/form_field_controller';

export default class ToggleCardController extends FormFieldController {
  public static targets = FormFieldController.targets;

  // TODO change when removing old components
  protected get invalidClassName() {
    return 'cnf-toggle-card--invalid';
  }
}
