import BaseController from 'src/lib/controller/base_controller';
import { addParametersToQueryString } from 'src/lib/util/add_parameter_to_url';
import { MDCMenuItemSelectedEvent } from 'src/types';

export default class PaginationController extends BaseController {
  public static targets = ['pageSizeLabel'];

  private declare readonly pageSizeLabelTarget: HTMLDivElement;

  public handlePageSizeChange(evt: Event) {
    const {
      detail: { item },
    } = evt as MDCMenuItemSelectedEvent;
    const pageSize = item.getAttribute('data-page-size');
    if (pageSize) {
      const params = {
        [this.pageSizeParamName]: pageSize,
      };

      // Optimistically update UI
      this.pageSizeLabelTarget.innerText = pageSize;

      // Redirect
      // TODO this probably should be the responsibility of a higher-level component.
      window.location.search = addParametersToQueryString(window.location.search, params);
    }
  }

  private get pageSizeParamName() {
    return this.data.get('pageSizeParameterName') || 'page_size';
  }
}
