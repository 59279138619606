import BaseController from 'src/lib/controller/base_controller';
import { addParametersToQueryString } from 'src/lib/util/add_parameter_to_url';

export default class TableHeaderCellController extends BaseController {
  public handleSortableClick() {
    const params = {
      sort_by: this.sortKey,
      sorted_direction: this.nextSortDirection,
    };
    window.location.search = addParametersToQueryString(window.location.search, params);
  }

  private get sortKey() {
    return this.getRequiredDataAttr('sortKey');
  }

  private get sortDirection() {
    return this.data.get('sortedDirection');
  }

  private get nextSortDirection() {
    return this.sortDirection === 'desc' ? 'asc' : 'desc';
  }
}
