import { IFormFieldError, IFormValidationEvent } from 'src/types';

import FlashController from 'src/controllers/base/flash_controller';
import BaseController from 'src/lib/controller/base_controller';

export default class FormExamplesFlashController extends BaseController {
  public handleFormValidatedEvent(evt: IFormValidationEvent) {
    const {
      detail: { valid, preamble, errors },
    } = evt;

    const fc = this.flashController;
    if (fc) {
      if (valid) {
        fc.hide();
      } else {
        // Show the flash box.
        // TODO this might be best wrapped up in a utility component.
        const errorMessages = errors.map(err => err.message);
        fc.renderHeadingWithListContent(preamble, errorMessages);
        fc.reveal();
      }
    }
  }

  private get flashController() {
    return this.getSpecificChildController<FlashController>(FlashController);
  }
}
