import { Controller, ControllerConstructor } from 'stimulus';

import { createCustomEvent } from 'src/lib/util/create_custom_event';

interface IControllerConnectionEvent
  extends CustomEvent<{
    identifier: string;
  }> {
  target: Element;
}

type DisconnectorFn = () => void;
type ConnectorFn = () => DisconnectorFn | void;
type ChildControllerConnectorFn = (controller: BaseController) => void;

// Intentionally an object so that === will compare against this exact reference.
// We could use a Symbol here, but IE11 will barf and I don't think it merits a full on polyfill.
const initializeReceipt = {};

const CONNECTION_EVENT = 'cnf:controller:connected';
const DISCONNECTION_EVENT = 'cnf:controller:disconnected';

export default abstract class BaseController extends Controller {
  private connectors: ConnectorFn[] = [];
  private disconnectors: DisconnectorFn[] = [];
  private connectionDisconnectors: DisconnectorFn[] = [];

  private childControllerConnectors: ChildControllerConnectorFn[] = [];
  private childControllerDisconnectors: ChildControllerConnectorFn[] = [];

  private childControllers: Set<BaseController> = new Set();

  /**
   * These lifecycle methods are provided by Stimulus.
   * We won't use these directly, instead we will call helpers which make it easier and safer to hook in to the controller lifecyle.
   */
  public initialize() {
    const receipt = this.onInitialize();
    if (receipt !== initializeReceipt) {
      // If we get here, it means that a subclass did not call `super.onInitialize()`.
      // This guard therefore ensures that every subclass should call `super.onInitialize()`.
      throw new Error(
        `onInitialize was implemented in ${this.identifier} without a call to super.onInitialize.`
      );
    }
  }

  public connect() {
    // Run all connectors. If any return disconnectors, cache them for later use.
    this.connectionDisconnectors = this.connectors.reduce(this.runConnector, []);
  }

  public disconnect() {
    // Run any permanent disconnectors.
    this.disconnectors.forEach(this.runDisconnector);

    // Run any disconnectors that were returned from connectors,
    // and clear the cache ready for the next run.
    this.connectionDisconnectors.forEach(this.runDisconnector);
    this.connectionDisconnectors = [];

    // Clear out any child controllers.
    this.childControllers.clear();
  }

  // Instead of using the lifecycle methods above, we'll use these.

  // This is called by `initialize`.
  public onInitialize() {
    this.onConnect(() => {
      let disconnector;

      // Listen for a connection event from an element further down in the DOM tree.
      this.element.addEventListener(CONNECTION_EVENT, this.handleControllerConnectedEvent);
      this.element.addEventListener(DISCONNECTION_EVENT, this.handleControllerDisconnectedEvent);

      // Remove the local cache when the child disconnects.
      disconnector = () => {
        this.emitDisconnectionEvent();
        this.element.removeEventListener(CONNECTION_EVENT, this.handleControllerConnectedEvent);
        this.element.removeEventListener(
          DISCONNECTION_EVENT,
          this.handleControllerDisconnectedEvent
        );
      };

      // Emit a connection event up through the DOM tree.
      // Workaround https://github.com/stimulusjs/stimulus/issues/222
      setTimeout(this.emitConnectionEvent, 0);

      return disconnector;
    });

    // This receipt is checked by `initialize`.
    // This ensures that any implementing method must call down the prototype chain,
    //  so that initializers are not skipped.
    // https://github.com/Microsoft/TypeScript/issues/21388#issuecomment-360214959
    return initializeReceipt;
  }

  /**
   * Adds a 'connector' function, which is run when the controller is connected.
   * This connector function can optionally return a 'disconnector' function,
   * which will be run when the controller is disconnected.
   * This couples the connect/disconnect lifecycle together for a particular operation.
   * (Inspired in part by the `useEffect` React hook).
   *
   * @param fn a connector function, when run optionally returns a 'disconnector' function
   */
  public onConnect(fn: ConnectorFn) {
    this.connectors.push(fn);
  }

  /**
   * Adds a 'disconnector' function, which is run when the controller is disconnected.
   *
   * @param fn a disconnector function
   */
  public onDisconnect(fn: DisconnectorFn) {
    this.disconnectors.push(fn);
  }

  /**
   * Adds a 'connector' function, which is run when a child controller is connected.
   * A child controller is defined as a controller which connects to a child DOM element.
   *
   * @param fn a connector function
   */
  public onChildControllerConnect(fn: ChildControllerConnectorFn) {
    this.childControllerConnectors.push(fn);
  }

  /**
   * Adds a 'disconnector' function, which is run when a child controller is disconnected.
   * A child controller is defined as a controller which connects to a child DOM element.
   *
   * @param fn a disconnector function
   */
  public onChildControllerDisconnect(fn: ChildControllerConnectorFn) {
    this.childControllerDisconnectors.push(fn);
  }

  /**
   * Finds all child controllers that are instances of the passed class.
   * @param ControllerClass controller class to use to find
   */
  public getSpecificChildControllers<T extends BaseController>(
    // Need to allow the `| Function` so that abstract classes can be used as the `ControllerClass` argument
    // https://github.com/Microsoft/TypeScript/issues/5843
    // tslint:disable-next-line ban-types
    ControllerClass: ControllerConstructor | Function
  ): T[] {
    const controllers: T[] = [];
    this.childControllers.forEach(c => {
      if (c instanceof ControllerClass) {
        controllers.push(c as T);
      }
    });
    return controllers;
  }

  /**
   * Finds the first child controller that is an instance of the passed class.
   * @param ControllerClass controller class to use to find
   */
  public getSpecificChildController<T extends BaseController>(
    // Need to allow the `| Function` so that abstract classes can be used as the `ControllerClass` argument
    // https://github.com/Microsoft/TypeScript/issues/5843
    // tslint:disable-next-line ban-types
    ControllerClass: ControllerConstructor | Function
  ): T | null {
    const result = Array.from(this.childControllers).find(c => c instanceof ControllerClass);
    return result ? (result as T) : null;
  }

  public findParentElementByTagName(el: Element, tagName: string) {
    tagName = tagName.toLowerCase();

    while (el && el.parentElement) {
      el = el.parentElement;
      if (el.tagName && el.tagName.toLowerCase() === tagName) {
        return el;
      }
    }
    return null;
  }

  // ---

  protected findController(element: Element, identifier: string): BaseController | null {
    // It would be more performant to pass the whole controller through the custom event,
    // so then it doesn't need to be looked up here every time the event is received.
    // I'm not sure how well a class sends via a DOM event, though!
    const controller = this.application.getControllerForElementAndIdentifier(element, identifier);
    return controller instanceof BaseController ? controller : null;
  }

  protected getRequiredDataAttr(key: string): string {
    const value = this.data.get(key);
    if (!value) {
      throw new Error(`${key} data attribute must be set for controller ${this.identifier}`);
    }
    return value;
  }

  protected getRequiredDataAttrs(...attrs: string[]): string[] {
    const found: string[] = [];
    const missing: string[] = [];

    attrs.forEach(key => {
      const value = this.data.get(key);
      if (!value) {
        missing.push(key);
      } else {
        found.push(value);
      }
    });

    if (missing.length === 1) {
      throw new Error(`${missing[0]} data attribute must be set for controller ${this.identifier}`);
    } else if (missing.length > 1) {
      throw new Error(
        `${missing.join(', ')} data attributes must be set for controller ${this.identifier}`
      );
    }

    return found;
  }

  protected getRequiredDataAttrAsJSON<T>(key: string): T {
    const value = this.data.get(key);
    if (!value) {
      throw new Error(`${key} data attribute must be set for controller ${this.identifier}`);
    }
    try {
      return JSON.parse(value);
    } catch (e) {
      throw new Error(
        `${key} data attribute for controller ${this.identifier} was not valid JSON: ${e.message}`
      );
    }
  }

  // ---

  private emitConnectionEvent = () => {
    const evt = createCustomEvent(CONNECTION_EVENT, {
      identifier: this.identifier,
    });
    this.element.dispatchEvent(evt);
  };

  private emitDisconnectionEvent = () => {
    const evt = createCustomEvent(DISCONNECTION_EVENT, {
      identifier: this.identifier,
    });
    this.element.dispatchEvent(evt);
  };

  private handleControllerConnectedEvent = (evt: Event) => {
    const {
      target,
      detail: { identifier },
    } = evt as IControllerConnectionEvent;
    const controller = this.findController(target, identifier);
    if (controller && controller !== this) {
      this.childControllers.add(controller);
      this.childControllerConnectors.forEach(cb => cb(controller));
    }
  };

  private handleControllerDisconnectedEvent = (evt: Event) => {
    const {
      target,
      detail: { identifier },
    } = evt as IControllerConnectionEvent;
    const controller = this.findController(target, identifier);
    if (controller && controller !== this) {
      this.childControllers.delete(controller);
      this.childControllerDisconnectors.forEach(cb => cb(controller));
    }
  };

  private runConnector = (disconnectors: DisconnectorFn[], connector: ConnectorFn) => {
    const d = connector();
    if (typeof d === 'function') {
      disconnectors.push(d);
    }
    return disconnectors;
  };

  private runDisconnector = (d: DisconnectorFn) => {
    d();
  };
}
