import { AxiosResponse } from 'axios';
import qs from 'querystringify';
import UnreadItemController from 'src/controllers/layouts/message_threads/unread_item_controller';
import axios from 'src/lib/axios';
import BaseController from 'src/lib/controller/base_controller';
import parseDateTime from 'src/lib/util/parse_datetime_attr';
import { IPathWithSharedInbox, NotificationEvent } from 'src/types';

export default class DynamicMessagesController extends BaseController {
  public static targets = ['items'];

  private declare readonly itemsTarget: HTMLDivElement;

  private messageControllers: UnreadItemController[] = [];

  public onInitialize() {
    this.onConnect(() => {
      if ('InstallTrigger' in window) {
        this.itemsTarget.style.minHeight = '0';
      }
    });

    this.onDisconnect(() => {
      this.messageControllers = [];
    });

    this.onChildControllerConnect((controller: BaseController) => {
      if (controller instanceof UnreadItemController) {
        this.messageControllers.push(controller);
      }
    });

    return super.onInitialize();
  }

  public load(e: CustomEvent): void {
    const {
      detail: { message },
    } = e as NotificationEvent;
    if (message.message_thread_uid !== this.data.get('thread-uid')) {
      this.fetchUnread(message.message_thread_uid);
    }
  }

  private fetchUnread(messageThreadUid: string): void {
    axios
      .get(this.unreadUrl(messageThreadUid))
      .then((response: AxiosResponse) => response.data)
      .then(html => this.addNotification(html, messageThreadUid))
      .catch((e: Error) => {
        alert(e.message);
      });
  }

  private addNotification(html: string, messageThreadUid: string) {
    const threadNotification = this.messageControllers.find(
      (controller: UnreadItemController) => controller.threadUid === messageThreadUid
    );

    if (threadNotification) {
      // Notification item exsists, remove old content
      this.removeItem(threadNotification);
    }

    this.prependNotificationNode(html);
  }

  private prependNotificationNode(html: string) {
    const sentAt = parseDateTime(html);
    const afterNode = this.firstItemSentBefore(sentAt);

    afterNode
      ? afterNode.scope.element.insertAdjacentHTML('beforebegin', html)
      : this.itemsTarget.insertAdjacentHTML('afterbegin', html);
  }

  private unreadUrl(messageThreadUid: string): string {
    let url = `/account/messages/threads/${messageThreadUid}/messages/unread`;
    const parts = qs.parse(window.location.search) as IPathWithSharedInbox;

    if (parts.shared_inbox_uid) {
      url += `?shared_inbox_uid=${parts.shared_inbox_uid}`;
    }

    return url;
  }

  private firstItemSentBefore(sentAt: number): UnreadItemController | undefined {
    return this.messageControllers.find(
      (controller: UnreadItemController) => controller.sentAt < sentAt
    );
  }

  private removeItem(controller: UnreadItemController) {
    const index = this.messageControllers.indexOf(controller);
    controller.scope.element.remove();
    this.messageControllers.splice(index, 1);
  }
}
