import ProductCategoryFilterController from 'src/controllers/shopping/product_category_filter_controller';
import BaseController from 'src/lib/controller/base_controller';
import {
  addParametersToQueryString,
  removeParameterFromQueryString,
} from 'src/lib/util/add_parameter_to_url';
import { MDCSelectChangeEvent } from 'src/types';
import { ICategory } from 'src/types';

export default class ProductCategoryFiltersController extends BaseController {
  public items!: ICategory[];

  public onInitialize() {
    this.items = this.parseItems();
    return super.onInitialize();
  }

  public tier0Selected({ detail: { index } }: MDCSelectChangeEvent) {
    this.tierSelected(index, 0);
  }

  public tier1Selected({ detail: { index } }: MDCSelectChangeEvent) {
    this.tierSelected(index, 1);
  }

  public tier2Selected({ detail: { index } }: MDCSelectChangeEvent) {
    this.tierSelected(index, 2);
  }

  public tier3Selected({ detail: { index } }: MDCSelectChangeEvent) {
    this.tierSelected(index, 3);
  }

  public submit(e: Event) {
    e.preventDefault();
    const lastSelectedUid =
      this.selectedValues[this.selectedValues.length - 1] || this.data.get('rootSluggedId')!;

    if (lastSelectedUid !== this.currentUid) {
      const newPathname = window.location.pathname.replace(this.currentUid, lastSelectedUid);
      // Remove the paging parameters
      window.location.href =
        newPathname + '?' + removeParameterFromQueryString(window.location.search, 'page');
    }
  }

  public embeddedSubmit(e: Event) {
    e.preventDefault();

    const lastSelectedUid =
      this.selectedValues[this.selectedValues.length - 1] || this.data.get('rootSluggedId')!;

    if (lastSelectedUid !== this.currentUid) {
      const newPathname = '/marketplace/categories/' + lastSelectedUid;
      window.location.pathname = newPathname;
    }
  }

  public reorder({ detail: { value } }: MDCSelectChangeEvent) {
    if (value) {
      const params = { order_by: value };
      window.location.search = addParametersToQueryString(window.location.search, params);
    } else {
      window.location.search = removeParameterFromQueryString(window.location.search, 'order_by');
    }
  }

  private tierSelected(index: number, tier: number) {
    const newSelectedCategory = this.tierControllers[tier].items[index - 1];
    const nextTierControllers = this.tierControllers.slice(tier + 1);

    for (const tierController of nextTierControllers) {
      tierController.disabled = true;
    }

    if (index > 0 && newSelectedCategory) {
      console.log(nextTierControllers.length);
      if (tier < this.tierControllers.length && newSelectedCategory.c?.length) {
        // Set options for next input
        const nextTierController = this.tierControllers[tier + 1];
        nextTierController.options = newSelectedCategory.c;
        nextTierController.disabled = false;
      }
    }
  }

  private parseItems(): ICategory[] {
    const itemsStr = this.data.get('categories');

    if (itemsStr !== null) {
      return JSON.parse(itemsStr) as ICategory[];
    }

    return [] as ICategory[];
  }

  private get currentUid(): string {
    const categoryAndQuery = window.location.pathname.split('/').pop() || '';
    return categoryAndQuery.split('?').pop() || '';
  }

  private get selectedValues(): string[] {
    return this.tierControllers.map(tc => tc.value).filter(v => !!v);
  }

  private get tierControllers(): ProductCategoryFilterController[] {
    return this.getSpecificChildControllers(
      ProductCategoryFilterController
    ) as ProductCategoryFilterController[];
  }
}
