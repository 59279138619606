import BaseController from 'src/lib/controller/base_controller';
import { format } from 'timeago.js';

export default class TimeagoController extends BaseController {
  private timeoutId!: number;
  private currentAgo!: string;
  private timestamp!: number;

  public onInitialize() {
    this.onConnect(() => {
      const timeStampString = this.data.get('timestamp') || '';
      this.timestamp = parseInt(timeStampString, 10);
      this.render();
      return () => {
        this.finish();
      };
    });

    return super.onInitialize();
  }

  private finish() {
    clearTimeout(this.timeoutId);
  }

  private render() {
    // Tick every minute if date is older than 1 minute, tick every second otherwise
    const timeout = Date.now() - this.timestamp > 60000 ? 60000 : 1000;
    const newAgo = format(this.timestamp);

    if (this.currentAgo !== newAgo) {
      this.element.textContent = newAgo;
      this.currentAgo = newAgo;
    }

    this.timeoutId = window.setTimeout(this.render.bind(this), timeout);
  }
}
